<template>
<v-menu bottom left>
    
    <template v-slot:activator="{ on, attrs }">

        <v-btn icon v-bind="attrs" v-on="on">
            <v-icon> mdi-dots-vertical </v-icon>
        </v-btn>

    </template>
    
    <v-list>

        <a href="https://forms.gle/B7sDfboFzPtctmXo7" target="_blank">

            <v-list-item>
                <v-list-item-icon> <v-icon> mdi-pen </v-icon> </v-list-item-icon>
                <v-list-item-content> <v-list-item-title> Feedback </v-list-item-title> </v-list-item-content>
            </v-list-item>

        </a>
        
        <a href="https://forms.gle/kDepe5crGeWVZrD49" target="_blank">

            <v-list-item>
              <v-list-item-icon> <v-icon> mdi-help </v-icon> </v-list-item-icon>
              <v-list-item-content> <v-list-item-title> Help </v-list-item-title> </v-list-item-content>
            </v-list-item>

        </a>
        
        <v-list-item router to="/signout">

            <v-list-item-icon> <v-icon> mdi-exit-to-app </v-icon> </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title> Signout </v-list-item-title>
            </v-list-item-content>

        </v-list-item>

    </v-list>
    
</v-menu>
</template>

<style scoped>

a {
  color             : #ffffff;
  text-decoration   : none;
}

</style>

<script>

export default { name   : 'NavbarMenu' }

</script>
