<template>
<v-app>

    <Root/>

</v-app>
</template>

<script>

import Root from './Root'

export default {
    name: "App",
    components : { Root }
};

</script>