<template>
<div>
    
    <v-container>
        
        <v-layout row wrap justify-space-around>

            <v-flex xs11 md6>

                <v-card color="white" outlined>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Profile Picture'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'You need to change your profile picture on gmail and then once sign-out and sign-in again to update your profile picture here'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3" size="100" tile>

                            <v-img style="border-radius:10px;" :src="profilePhoto"></v-img>

                        </v-avatar>

                    </div>

                </v-card>

                <br />

                <v-card color="white" outlined>

                    <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="form-container"
                    >

                        <div class="text-right">
                            <v-btn
                            class="ma-2"
                            v-on:click="disabled = false"
                            tile
                            outlined
                            color="primary"
                            >
                            <v-icon left>mdi-pencil</v-icon>Edit
                            </v-btn>
                        </div>

                        <v-text-field
                            v-model="name"
                            counter="100"
                            :rules="nameRules"
                            label="Name"
                            required
                            :disabled="disabled"
                            clearable
                        ></v-text-field>
                        
                        <p style="color: green;font-size: 12px;">Note : If your College or Institute name is not there in the list, mail us at preskilet@gmail.com</p>

                        <v-combobox
                            :items="organizationTerms"
                            v-model="organizationName"
                            counter="100"
                            :rules="organizationNameRules"
                            label="College / Organization Name"
                            required
                            :disabled="disabled"
                            clearable
                        ></v-combobox>

                        <!--
                        <v-combobox
                            :items="departmentTerms"
                            v-model="departmentName"
                            counter="100"
                            :rules="departmentNameRules"
                            label="Department Name"
                            required
                            :disabled="disabled"
                            clearable
                        ></v-combobox>
                        -->

                        <v-select
                            :disabled="disabled"
                            v-model="genderType"
                            :items="genderTypes"
                            label="Select Gender Type"
                            data-vv-name="select"
                            :rules="[(v) => !!v || 'Gender type is required']"
                            required
                        ></v-select>
                        
                        <!--
                        <v-select
                            :disabled="disabled"
                            v-model="professionType"
                            :items="professionTypes"
                            label="Select Profession Type"
                            data-vv-name="select"
                            :rules="[(v) => !!v || 'Profession type is required']"
                            required
                        ></v-select>
                        -->
                        
                        <v-combobox
                            v-if="professionType === 'Student'"
                            :items="regnoTerms"
                            v-model="regno"
                            counter="100"
                            :rules="regnoRules"
                            label="Registration Number /  PRN "
                            required
                            :disabled="disabled"
                            clearable
                        ></v-combobox>
                        
                        
                        <v-combobox
                            v-if="professionType === 'Student'"
                            :items="seatnoTerms"
                            v-model="seatno"
                            counter="100"
                            :rules="seatnoRules"
                            label="Exam Seat Number"
                            required
                            :disabled="disabled"
                            clearable
                        ></v-combobox>


                        <v-combobox
                            v-if="professionType === 'Student'"
                            :items="rollnoTerms"
                            v-model="rollno"
                            counter="100"
                            :rules="rollnoRules"
                            label="Roll Number"
                            required
                            :disabled="disabled"
                            clearable
                        ></v-combobox>

                        <v-combobox
                            v-if="professionType === 'Student'"
                            :items="divisionTerms"
                            v-model="division"
                            counter="100"
                            :rules="divisionRules"
                            label="Division Name"
                            required
                            :disabled="disabled"
                            clearable
                        ></v-combobox>

                        <v-combobox
                            v-if="professionType === 'Student'"
                            :items="batchTerms"
                            v-model="batch"
                            counter="100"
                            :rules="batchRules"
                            label="Batch Name"
                            required
                            :disabled="disabled"
                            clearable
                        ></v-combobox>

                        <v-combobox
                            v-if="professionType === 'Student'"
                            v-model="parentEmailId"
                            counter="50"
                            :rules="emailIdRules"
                            label="Parent Email Id"
                            required
                            :disabled="disabled"
                            clearable
                        ></v-combobox>


                        <br />

                        <div class="text-right">

                            <v-btn
                            class="ma-2"
                            color="primary"
                            depressed
                            v-on:click="validate"
                            :disabled="disabled"
                            >

                                <v-icon dark left>mdi-checkbox-marked-circle</v-icon>Save

                            </v-btn>

                            <v-btn
                            class="ma-2"
                            color="primary"
                            v-on:click="cancelFromEditing"
                            :disabled="disabled"
                            >

                                <v-icon dark left>mdi-close-circle</v-icon>Cancel

                            </v-btn>

                        </div>

                    </v-form>

                </v-card>

            </v-flex>

            <v-flex xs11 md4>
                
                <v-card 
                    :flat="cardFlatFlag"
                    :outlined="cardOutlineFlag"
                    class="mx-auto sticky"
                    v-if="false"
                >
            
                    <v-list-item two-line class="RightSideAd">
                        
                        <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                        
                        <v-list-item-content>
                            
                            <v-list-item-title>Google Ads</v-list-item-title>
                            
                            <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                        
                        </v-list-item-content>
                                    
                        <v-btn color="primary" size="35" tile large icon router >
                            <v-icon left> mdi-compass-outline</v-icon>
                        </v-btn>


                    </v-list-item>
                    
                        <!-- Profile Page - Right Ad -->
                        <div class="ad-container">
                            <Adsense
                                style="display:block"
                                data-ad-client="ca-pub-2789565594210548"
                                data-ad-slot="5603006191"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            ></Adsense>
                        </div>
        
                </v-card>
              
            
            </v-flex>

        </v-layout>

    </v-container>

</div>
</template>

<style scoped>
.sticky{
position: relative;
}



.RightSideAd{
    padding-left: 0px;
    padding-right: 0px;
}

/* for desktop */
@media screen and (min-width: 650px) {
.RightSideAd{
    padding-left: 4%;
    padding-right: 4%;
}

.sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 2;
    width: auto !important;
}

  .sticky2{
    position: -webkit-sticky;
    position: sticky;
    top: 27rem;
    z-index: 2;
    width: auto !important;
    margin-top: 280%;
}

}


.form-container {
  padding   : 20px;
}




</style>

<script>

import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;



// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

export default {
    
    name        : "Profile",
    
    computed    : { ...mapGetters({ userData    : "User/userData" }) },
  
    mounted() {
        
        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }


        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Profile Page");
        
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
        this.getCollegeList()
        if (this.userData.signedIn) {
            this.getProfileData();
        } else {
            console.log("no one is logged in ");
        }

    },

    data() {
        
        return {
            cardFlatFlag    : true,
            cardOutlineFlag : false,

            profilePhoto    : "",
            disabled        : true,
            valid           : true,
            
            name            : "",
            nameRules       : [
                (v) => !!v || "Name is required",
                (v) => (v && v.length <= 100) || "Name must be less than 100 characters",
            ],

            organizationTerms       : [],
            organizationName        : "",
            organizationNameRules   : [
                (v) => !!v || "College / Organization Name is required",
                (v) => (v && v.length <= 100) || "College / Organization must be less than 100 characters",
            ],

            departmentTerms       : ["Not Applicable", "School Of Electrical Engineering","School Of Computer Engineering & Technology","School Of Mechanical & Civil Engineering","School Of Chemical Engineering","School Of Design","School Of Humanities And Engineering Sciences","Computer Department","Information Technology Department","Electrical Department","Civil Department","Mechanical Department"],
            departmentName        : "",
            departmentNameRules   : [
                (v) => !!v || "Department Name is required",
                (v) => (v && v.length <= 100) || "Department must be less than 100 characters",
            ],

            professionType        : "",
            professionTypes       : ["Student", "Teacher", "Candidate", "Recruiter"],

            genderType        : "",
            genderTypes       : ["Male", "Female", "Other"],

            regno: "",
            regnoTerms: [],
            regnoRules: [(v) => !!v || "Register Number is required", (v) => (v && v.length <= 100) || "Registration Number must be less than 100 characters" ],
            
            
            seatno: "",
            seatnoTerms: [],
            seatnoRules: [(v) => !!v || "Exam Seat Number is required", (v) => (v && v.length <= 100) || "Exam Seat Number must be less than 100 characters" ],

            parentEmailId:"",
            emailIdRules: [(v) => !!v || "Email ID is required", (v) => (v && v.length <= 50) || "Email Id must be less than 50 characters" ],
            
            rollno: "",
            rollnoTerms: [],
            rollnoRules: [(v) => !!v || "Roll Number is required", (v) => (v && v.length <= 100) || "Roll must be less than 100 characters" ],

            division: "",
            divisionTerms: ["A1","B1","C1","D1","A2","B2","C2","D2","A3","B3","C3","D3","A4","B4","C4","D4"],
            divisionRules: [(v) => !!v || "DivisionName is required", (v) => (v && v.length <= 100) || "Division Name must be less than 100 characters" ],

            batch: "",
            batchTerms: ["A11","A12","A13","B11","B12","B13","C11","C12","C13","D11","D12","D13","A21","A22","A23","B21","B22","B23","C21","C22","C23","D21","D22","D23","A31","A32","A33","B31","B32","B33","C31","C32","C33","D31","D32","D33"],
            batchRules: [(v) => !!v || "Batch Name is required", (v) => (v && v.length <= 100) || "Division Name must be less than 100 characters" ],

        };

    },
    
    methods: {
        
        cancelFromEditing() {
            window.location.href = "/profile";
        },

        validate() {
            
            this.$refs.form.validate();
            
            if (this.$refs.form.validate()) {
                this.setProfiledata();
            }

        },

        reset() {
            this.$refs.form.reset();
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },

        setProfiledata() {
            
            this.$store.commit("User/toggleProgressLoader", false);
            window.localStorage.accountType = this.professionType;

            

            let collegeId;
			this.collegeList.map(college=>{
				if(college.name == this.organizationName)
				{
					collegeId = college.collegeId
				}
			})

            let data = {
				collegeId 		:collegeId,
                name            : this.name,
                account         : this.professionType,
                gender          : this.genderType,


                organization    : this.organizationName,
                // department      : this.departmentName,
                
                regno           : this.regno, 
                seatno          : this.seatno,
                rollno          : this.rollno,
                div             : this.division,
                batch           : this.batch ,
                parentEmailId   : this.parentEmailId         
            };


            console.log("Profile setup data : ",data);

            let endPoint = apis[apis.env].editProfile;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "post",
                data,
                withCredentials : true,
                headers         : { authorization    : this.userData.token }
            })
            .then((res) => {
            
                console.log("success : ", res.data.result.message);
                this.$store.commit("User/toggleProgressLoader", false);
                this.disabled = true;
                this.$store.commit("User/showSuccessAlert", res.data.result.message);
                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Profile Data Updated");
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error In Profile",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        getProfileData() {
            
            let endPoint = apis[apis.env].getProfile;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {

                this.$store.commit("User/toggleProgressLoader", false);
                
                var mydata         = res.data.result.data.profile;
                this.professionType = mydata.accountType;

                
                
                this.name           = mydata.name;
                this.profilePhoto   = mydata.profilePhoto;
                this.organizationName   = mydata.organization;
                this.genderType = mydata.gender;
                this.departmentName     = mydata.department;
                this.regno          = mydata.regno;
                this.seatno         = mydata.seatno;
                this.rollno         = mydata.rollno;
                this.division            = mydata.div;
                this.batch          = mydata.batch;
                this.parentEmailId   = mydata.parentEmailId     

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Profile Data Retrived");
                
            })
            .catch((err) => {

                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error In Profile",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        getCollegeList() {
		
			this.organizationTerms = [];
			let endPoint = apis[apis.env].getCollegeList;
			this.$store.commit("User/toggleProgressLoader", true);
			
			axios(endPoint, {
				method          : "get",
				withCredentials : true,
				headers         : { authorization: this.userData.token }
			})
			.then((res) => {
				this.$store.commit("User/toggleProgressLoader", false);
				this.collegeList = res.data.result.data; 

				this.collegeList.map(college=>{
					this.organizationTerms.push(college.name)
				}) 
				console.log("this is the college list : ",this.collegeList)      
			})
			.catch((err) => {

				this.$store.commit("User/toggleProgressLoader", false);
				console.log("Error In Profile",err);
				let errorMsg = utilities.errorHandler(err);
				this.$store.commit("User/showErrorAlert", errorMsg);
			
			});
    	}
    
    }

};

</script>
