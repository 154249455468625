import  Vue         from    "vue";
import  VueRouter   from    "vue-router";

// Views
import  Home        from    "@/views/Home.vue";
import  Feed        from    "@/views/Feed.vue";
import  Explore        from    "@/components/ExploreVideos.vue";
import  Trending        from    "@/views/Trending.vue";
import  Signin      from    "@/views/Signin.vue";
import  SignOut     from    "@/views/SignOut.vue";
import  Profile     from    "@/views/Profile.vue";
import  SelectAccountType     from    "@/views/SelectAccountType.vue";
import  Pret        from    "@/views/Pret.vue";
import  EditPret    from    "@/views/EditPret.vue";
import  SavedPrets    from    "@/views/SavedPrets.vue";
import  Search      from    "@/views/Search.vue";
import  Preskilet   from    "@/views/Preskilet.vue";
import  Watch       from    "@/views/Watch.vue";
import  TestAd       from    "@/views/TestAd.vue";
import  Followers   from    "@/views/Followers.vue";
import  Following   from    "@/views/Following.vue";
import  Groups      from    "@/views/Groups.vue";
import  Tasks       from    "@/views/Tasks.vue";
import  TaskPrets   from    "@/views/TaskPrets.vue";
import  GroupRequests   from "@/views/GroupRequests.vue"
import  GroupFollowers  from "@/views/GroupFollowers.vue"
import  Attendance  from "@/views/Attendance.vue"
import  HowToUse  from "@/views/HowToUse.vue"

// Static Views
import  Termsandconditions  from    "@/views/Termsandconditions.vue";
import  Privacypolicy       from    "@/views/Privacypolicy.vue";
import  AboutUs             from    "@/views/AboutUs.vue";
import  ContactUs           from    "@/views/ContactUs.vue";

Vue.use(VueRouter);

const routes = [
    { path: "/", name: "Home", component: Home },
    { path: "/explore", name: "Explore", component: Explore }, 
    { path: "/how-to-use-preskilet", name: "HowToUse", component: HowToUse }, 
       
    { path: "/feed", name: "Feed", component: Feed },
    { path: "/trending", name: "Trending", component: Trending },
    { path: "/googleadtest", name: "TestAd", component: TestAd },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/selectaccounttype",
    name: "SelectAccountType",
    component: SelectAccountType,
  },
  {
    path: "/pret",
    name: "Pret",
    component: Pret,
  },
  {
    path: "/editpret",
    name: "EditPret",
    component: EditPret,
  },
  {
    path: "/savedprets",
    name: "SavedPrets",
    component: SavedPrets,
  },
  {
    path: "/followers",
    name: "Followers",
    component: Followers,
  },
  {
    path: "/following",
    name: "Following",
    component: Following,
  },
  {
    path: "/groups",
    name: "Groups",
    component: Groups,
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: Tasks,
  },  
  {
    path: "/grouprequests",
    name: "GroupRequests",
    component: GroupRequests,
  },  
  {
    path: "/groupfollowers",
    name: "GroupFollowers",
    component: GroupFollowers,
  },
  {
    path: "/attendance",
    name: "Attendance",
    component: Attendance,
  },
  {
    path: "/search/",
    name: "Search",
    component: Search,
  },
  //common menu items links that is static pages
  {
    path: "/termsandconditions",
    name: "Termsandconditions",
    component: Termsandconditions,
  },
  {
    path: "/privacypolicy",
    name: "Privacypolicy",
    component: Privacypolicy,
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/contactus",
    name: "ContactUs",
    component: ContactUs,
  },

  // common menu items ended *****
  {
    path: "/signout",
    name: "SignOut",
    component: SignOut,
  },
  {
    path: "/task/prets/:q",
    name: "TaskPrets",
    component: TaskPrets,
  },

  {
    path: "/search/:q",
    name: "SearchByParams",
    component: Search,
  },
  {
    path: "/watch",
    name: "Watch",
    component: Watch,
  },
  {
    path: "/:email",
    name: "Preskilet",
    component: Preskilet,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
