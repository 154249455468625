var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.userData.signedIn)?_c('div',{staticClass:"home"},[_c('v-container',[_c('v-dialog',{attrs:{"persistent":"","max-width":"560"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Copy link")]),_c('v-card-text',[_vm._v("Please upadate your browser so you can directly share your resume, otherwise you can use the below link, click on copy button to copy the link")]),_c('v-text-field',{staticClass:"ma-3",attrs:{"id":"myinput"},model:{value:(_vm.shareableLink),callback:function ($$v) {_vm.shareableLink=$$v},expression:"shareableLink"}}),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"tooltip"},[_c('button',[_c('span',{staticClass:"tooltiptext",attrs:{"id":"myTooltip"}},[_vm._v("Copy to clipboard")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.myLink,"mouseout":_vm.outFunc}},[_vm._v("Copy")])],1)]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Close")])],1)],1)],1),_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",attrs:{"src":_vm.vectorImages.Welcome}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Nestimatic')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'Welcome to Nestimatic, before you start, please go through a brief introduction of sections in our website, you will find all of this options in top left menu, once you create account with us. it will help you to use our services easily.'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{staticStyle:{"border-radius":"10%"},attrs:{"src":_vm.vectorImages.Welcome}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",attrs:{"src":_vm.vectorImages.search}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Search')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'Search your teachers by names with the help of nestimatic, to follow groups created by them & submit the assignments'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":_vm.vectorImages.search}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",attrs:{"src":_vm.vectorImages.profile}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Profile')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'Profile is one of the most important part of the Nestimatic Profile. Make sure you enter corrent information, because this information is used while grading your assignements & taking your attendance.'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":_vm.vectorImages.profile}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",staticStyle:{"border-radius":"100%"},attrs:{"src":_vm.vectorImages.pret}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Upload Assignments')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'You can upload your assignments from this section'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":_vm.vectorImages.pret}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",staticStyle:{"border-radius":"100%"},attrs:{"src":_vm.vectorImages.group}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Groups')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'You can create groups (Batch-wise, Division-wise, Department-wise, College-wise) on preskilet to create tasks (assignments) for that group. Interested Users can follow those groups and you will get a group request. As soon as you accept the request, each group member will get notifications whenever you assign task (Assignment) for that group.'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{staticStyle:{"border-radius":"10%"},attrs:{"src":_vm.vectorImages.group}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",staticStyle:{"border-radius":"100%"},attrs:{"src":_vm.vectorImages.task}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Tasks')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'You can create tasks on various topics so that your group members can upload assignments for respective task and then you can grade those assignments and generate a grade report in excel sheet.'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{staticStyle:{"border-radius":"10%"},attrs:{"src":_vm.vectorImages.task}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",staticStyle:{"border-radius":"100%"},attrs:{"src":_vm.vectorImages.excel}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Download Excel')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'You can download excel sheet for a particular group containing grades of every task assignments (and also attendance of students) along with details of group members such as Registration number, Roll number, Batch name, etc in just a single click!'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{staticStyle:{"border-radius":"10%"},attrs:{"src":_vm.vectorImages.excel}})],1)],1)],1),_c('br')],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }