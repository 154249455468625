const apiConfig = require('./apiConfig');

module.exports = {
  errorHandler: (error) => {
    console.log(error);
    if (error.response && error.response.status === 403) {
      // window.location.href = "/signin";
    } else if (error.response) {
      return error.response.data.exception.error.message;
    } else {
      return "Something went wrong, please try again...";
    }
  },

  logger: (log) => {
    if (apiConfig.default.env === "local") {
      console.log(log);
    }
  },

  vectorImages: {
    individualAccount:
      "https://firebasestorage.googleapis.com/v0/b/curiculo-firebase.appspot.com/o/assets%2Fimages%2FindividualAccount.jpg?alt=media&token=cd72a38c-7e4f-48a5-a7e6-32d113d076f0",
    organizationAccount:
      "https://firebasestorage.googleapis.com/v0/b/curiculo-firebase.appspot.com/o/assets%2Fimages%2ForganizationAccount.png?alt=media&token=55d902f9-d5af-4ccc-81ff-b998b2e400d5",
    
    homePageAnimtaion: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2FHome%20Page%20Animtation.gif?alt=media&token=9ba2134b-cbb1-460e-b1c0-36ac58d0bfa9",
    
    preskiletAnimatedIcon: "https://cdn.dribbble.com/users/1986212/screenshots/6144741/sign-in---character-for-animation.gif",
    
    //home image
    presentation: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Fpresentation.gif?alt=media&token=4fa33c95-d0fb-46cc-81bc-d1d365bbb859",
    
    dashboard: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2FDashboard.gif?alt=media&token=8f5949b0-7507-432a-83f5-132f9a42de5d",
    
    notifications: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Fnotifications.gif?alt=media&token=199f5424-3436-4678-863f-e960b9ce9312",
    
    installApp: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2FinstallApp.gif?alt=media&token=953489d9-b346-4bbe-9d07-6c71b16893ce",
    
    Welcome: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2FWelcome.gif?alt=media&token=aa2ed503-feb1-43a6-9ca6-4cbd6eddd5f4",
    
    search: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Fserach.gif?alt=media&token=0f2e30d1-2cd6-4b5c-b009-571967c2538c",
    
    suggestions: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Fsuggestions.gif?alt=media&token=d5ccf11f-88ec-4258-beb4-c542ea743568",
    
    profile: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Fprofile.gif?alt=media&token=10cfb035-fecb-49d1-8e09-0d1d34422794",
    
    signout: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Fsignout.gif?alt=media&token=ab873424-f889-45a6-9d05-b43b64c6baa4",
    
    pret: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Fpret.gif?alt=media&token=5550fa52-d0ee-4d6c-9541-990ce7779546",
    
    editPret: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Fedit%20pret.gif?alt=media&token=a1048bfb-d8aa-482d-956f-bdbdb3ad1611",
    
    followers: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2FFollowers.gif?alt=media&token=99c1eec5-9048-4892-b365-221219657dd3",
    
    following: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Ffollowing.gif?alt=media&token=21ae1f0e-abda-4a1c-b2eb-1b406b70e105",
    
    group: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Fgroup.gif?alt=media&token=7bad026a-e8d4-4bac-b863-8f1c7708f214",
    
    task: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Ftask.gif?alt=media&token=4230659b-f938-42a9-8abc-d2c775a24f40",
    
    excel: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2FDownload%20Excel.gif?alt=media&token=3458713e-548b-421c-b1a3-eb7a95419733",
    
    trending:"https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Ftrending.gif?alt=media&token=175e41c8-aa9a-43d9-b1b8-fce092ce20eb",

    aboutUsProblem: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Fs-removebg.png?alt=media&token=8b443a2a-1c98-46dd-bc72-ee30a1c04f94"

  },

  emptyState: {
    prets: {
      image:
        "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Fpret.gif?alt=media&token=5550fa52-d0ee-4d6c-9541-990ce7779546",
      message: "You haven't uploaded any prets yet...",
    },
    followers: {
      image:
        "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2FFollowers.gif?alt=media&token=99c1eec5-9048-4892-b365-221219657dd3",
      message: "No followers yet...",
    },
    following: {
      image:
        "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Ffollowing.gif?alt=media&token=21ae1f0e-abda-4a1c-b2eb-1b406b70e105",
      message: "You haven't followed anyone yet..., Search people to follow...",
    },
    groupRequest: {
        image:
        "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2FgroupRequest.gif?alt=media&token=520414d5-49be-4e24-9d19-5cf2a3f3415a",
        message : "Select Group Name to see the requests for that Group"
    },
    groupFollowers: {
        image:
        "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2FGroup%20Followers.gif?alt=media&token=71aacca1-dda7-4912-a498-1b6b7372bc18",
        message : "Select Group Name to see the Followers of that Group"
    },
    group: {
        image:
        "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Fgroup.gif?alt=media&token=7bad026a-e8d4-4bac-b863-8f1c7708f214",
        message : "Create Batch or Department as a group"
    },
    task: {
        image:
        "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Ftask.gif?alt=media&token=4230659b-f938-42a9-8abc-d2c775a24f40",
        message : "Click on 'Create task for Group' to create Task for any group and if your want to see tasks for any group then just select the Group "
    },

    excel: {
        image:
        "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2FDownload%20Excel.gif?alt=media&token=3458713e-548b-421c-b1a3-eb7a95419733",
        message : "Downloading Excel"
    },

    trending: {
        image:
        "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Ftrending.gif?alt=media&token=175e41c8-aa9a-43d9-b1b8-fce092ce20eb",
        message : "No Trending Videos"
    },

    aboutUsProblem:{
        image:
        "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fvectors%2Fs-removebg.png?alt=media&token=8b443a2a-1c98-46dd-bc72-ee30a1c04f94",
        message : "About us"
    }

  },
};
