<template>
<div>

    <nav v-if="userData.signedIn">
        
        <v-toolbar flat style="border-bottom: 1px solid grey;margin-bottom: 3%;">
            
            <v-app-bar-nav-icon v-if="!drawer" @click="drawer = !drawer"></v-app-bar-nav-icon>
            
            <v-toolbar-title>
            
                <a class="Brand-logo" href="/">
                    <span class="blue--text">Nestimatic</span>
                </a>
            
            </v-toolbar-title>
            
            <v-spacer></v-spacer>
            
            <v-btn
                v-if=" this.$route.name != 'SearchByParams' && this.$route.name != 'Search'"
                class="ma-2"
                tile
                large
                icon
                router
                to="/search/"
            >
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            
            <v-btn
                class="ma-2 hidden-sm-and-down"
                tilex
                outlined
                router
                :to="'/' + userData.email"
            >
                <v-icon left>mdi-account</v-icon>
                My Profile
            </v-btn>
            
            <v-btn
                class="ma-2 hidden-md-and-up"
                tile
                large
                icon
                router
                :to="'/' + userData.email"
            >
                <v-icon left>mdi-account</v-icon>
            </v-btn>
            
            <InstallApp/>
            <NavbarMenu/>
            
        </v-toolbar>
        
        <v-navigation-drawer color="whitesmoke" dark v-model="drawer" app>
            
            <v-list-item class="px-2" style="padding-top: 8px;">
                
                <v-list-item-avatar>
                    <v-img :src="profile.profilePhoto"></v-img>
                </v-list-item-avatar>
                
                <v-list-item-title>{{ profile.name }}</v-list-item-title>
                
                <v-btn icon @click="drawer = !drawer">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            
            </v-list-item>

            <span v-if="menuOptions.length === 0">
              <center>
                <p style="margin-top: 30px; color: white;">Your token has been expired</p>
                <v-btn color="white" tile large @click="forceRefreshPage"  class="black--text" 
                > Click to Refresh </v-btn>

              </center>
            </span>

            <v-list dense>
                
                <v-list-item
                    v-for="item in menuOptions"
                    :key="item.title"
                    link
                    router
                    :to="item.route"
                >
                    
                    <v-list-item-icon>
                        <i size="30" v-if="item.title == 'Settings'" class="material-icons">settings</i>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                
                </v-list-item>
            
            </v-list>
        
        </v-navigation-drawer>

    </nav>

    <nav v-if="!userData.signedIn">
        
        <v-toolbar flat style="border-bottom: 1px solid grey;margin-bottom: 3%;">
            
            <v-toolbar-title>
                
                <a class="Brand-logo" href="/">
                    <span class="blue--text">Nestimatic</span>
                </a>
                
            </v-toolbar-title>
                
            <v-spacer></v-spacer>
            
            <v-btn
                v-if="this.$route.name != 'Signin'"
                class="ma-2 hidden-sm-and-down blue--text"
                tile
                outlined
                router
                to="/signin"
            >
                <v-icon left>mdi-account</v-icon>Sign-in</v-btn>
                
                <v-btn 
                    v-if="this.$route.name != 'Signin'"
                    class="ma-2 hidden-md-and-up blue--text"
                    tile
                    large
                    icon
                    router
                    to="/signin"
                >
                    <v-icon left>mdi-account</v-icon>
                </v-btn>
            
            </v-toolbar>
        
    </nav>

</div>
</template>

<style scoped>

.Brand-logo {
    text-decoration: none !important;
    color: black !important;
}

a {
    color: #ffffff;
    text-decoration: none;
}

</style>

<script>
import { mapGetters } from "vuex";

import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import InstallApp from "@/components/InstallApp";
import NavbarMenu from "@/components/NavbarMenu";

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

export default {
    
    name        :   "SignedInNavbar",
    
    components  :   { InstallApp, NavbarMenu },
    
    computed    :   { ...mapGetters({ userData: "User/userData" }) },
    
    created() {
        
        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("SignedInNavbar Component");

        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
        
        if (this.userData.signedIn) { this.setProfile() };
    },
    
    data() {
        return {
            profile :   [],
            drawer  :   false,
            tokenExpiredForceRefreshNeeded: false,
            
            menuOptions: [],

            // menuOptions: [
            //     {   title: "Home",          route: "/",             icon: "mdi-home"                },
            //     {   title: "Trending",      route: "/trending",         icon: "mdi-fire"      },
            //     {   title: "Feed",          route: "/feed",         icon: "mdi-view-dashboard"      },
            //     {   title: "Upload Pret (Video)",          route: "/pret",         icon: "mdi-flag-triangle"       },
            //     {   title: "Edit/Delete Prets",    route: "/editpret",     icon: "mdi-grease-pencil"       },
            //     {   title: "Saved Prets",       route: "/savedprets",   icon: "mdi-bookmark"      },
            //     {   title: "Profile",       route: "/profile",      icon: "mdi-account"             },
            //     {   title: "Followers",     route: "/followers",    icon: "mdi-theater"             },
            //     {   title: "Group that you joined (student)",     route: "/following",    icon: "mdi-cctv"                },
            //     {   title: "Groups",        route: "/groups",       icon: "mdi-account-multiple"    },
            //     {   title: "Tasks",         route: "/tasks",        icon: "mdi-file-document"       },
            //     {   title: "Group Requests",route: "/grouprequests",icon: "mdi-account-plus"        },
            //     {   title: "Group Followers",route: "/groupfollowers",icon: "mdi-account-multiple-plus"      },
            //     {   title: "How to Use Preskilet ?",     route: "/how-to-use-preskilet", icon: "mdi-file-document"      },
            // ]
        }
    },
    
    methods: {

      forceRefreshPage(){
        location.reload(true);
      },
        
        setProfile() {
            
            let endPoint = apis[apis.env].getProfile;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization : this.userData.token }
            })
            .then((res) => {
              console.log("this is profile data extorted : ", res);
                
                this.$store.commit("User/toggleProgressLoader", false);
                let data = res.data.result.data.profile;
                this.profile = data;

this.profile.account = data.accountType
                
                if(String(this.profile.account).toLowerCase() == 'teacher')
                {
                    this.menuOptions = [
                        {   title: "Home",          route: "/",             icon: "mdi-home"                },
                        {   title: "Edit Profile",       route: "/profile",      icon: "mdi-account"             },
                        {   title: "Groups",        route: "/groups",       icon: "mdi-account-multiple"    },
                        {   title: "Tasks",         route: "/tasks",        icon: "mdi-file-document"       },
                        {   title: "Attendance",         route: "/attendance",        icon: "mdi-file-document"       },
                        {   title: "Group Requests",route: "/grouprequests",icon: "mdi-account-plus"        },
                        {   title: "Group Followers",route: "/groupfollowers",icon: "mdi-account-multiple-plus"      }
                        // {   title: "How to Use Preskilet ?",     route: "/how-to-use-preskilet", icon: "mdi-file-document"      },
                    ]
                }
                else if(String(this.profile.account).toLowerCase() == 'student'){
                    
                    this.menuOptions = [
                        {   title: "Home",          route: "/",             icon: "mdi-home"                },
                        {   title: "Upload Assignment",          route: "/pret",         icon: "mdi-flag-triangle"       },
                        {   title: "Edit Profile",       route: "/profile",      icon: "mdi-account"             },
                        {   title: "Group that you joined ",     route: "/following",    icon: "mdi-cctv"                }
                        // {   title: "How to Use Preskilet ?",     route: "/how-to-use-preskilet", icon: "mdi-file-document"      },
                    ]
                }
                else
                {
                    this.menuOptions = [
                        {   title: "Please Sign In",          route: "/signin",         icon: "mdi-flag-triangle"       }
                    ]
                }
                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Navbar Profile Data Displayed");
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log(" This is the error ", err);
                let errorMsg = utilities.errorHandler(err);
                console.log(errorMsg);
            });
                }
    
    }

};

</script>
