<template>
<div>
    
    <v-container>
        <v-layout row wrap justify-space-around>
            
            <v-flex xs12 md6>

                <LandUpInfo />
            
            </v-flex>
            
            <v-flex xs11 md4>

                <v-card 
                    :flat="cardFlatFlag"
                    :outlined="cardOutlineFlag"
                    class="mx-auto sticky"
                    v-if="false"
                >
            
                    <v-list-item two-line class="RightSideAd">
                        
                        <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                        
                        <v-list-item-content>
                            
                            <v-list-item-title>Google Ads</v-list-item-title>
                            
                            <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                        
                        </v-list-item-content>
                                    
                        <v-btn color="primary" size="35" tile large icon router >
                            <v-icon left> mdi-compass-outline</v-icon>
                        </v-btn>


                    </v-list-item>
                    
                        <div class="ad-container">
                            <Adsense
                                style="display:block"
                                data-ad-client="ca-pub-2789565594210548"
                                data-ad-slot="3924212579"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            ></Adsense>
                        </div>
        
                </v-card>

            </v-flex>

        </v-layout>
    
    </v-container>

    <br>
    <br>
    <footer class="site-footer">

        <div class="container">            
            <v-layout row wrap justify-space-around>
                
                <v-flex xs11 md8>
                    
                    <h6>About</h6>
                    
                    <br />
                    
                    <p class="text-justify white--text" dark>
                        
                        Preskilet stands for presenting your soft skill set !
                        Preskilet is the world’s first professional video platform,
                        At preskilet you can create your professional video resume containing videos about
                        projects and achivements etc. You can share your video profile
                        with potential recruiters for increasing chances of placements.
                        With preskilet your video profile is live At
                        
                        <b>https://preskilet.com/your email</b>. <br /> <br />
                    
                    </p>
                
                </v-flex>
                
                <v-flex xs11 md3>
                    
                    <h6>Quick Links</h6>
                    
                    <v-list style="background: #26272b;" dark>
                        
                        <v-list-item v-for="(item, i) in commonMenuitems" :key="i" router :to="item.route">
                            
                            <v-list-item-icon>
                                <i size="30" v-if="item.title == 'Settings'" class="material-icons"> settings </i>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                            
                        </v-list-item>
                    
                    </v-list>
                </v-flex>
                
            </v-layout>
            
            <br /><hr /><br />
            
            <v-layout row wrap justify-space-around>
            
                <v-flex xs11 md9>
                    <p class="ma-3 white--text text-center"> Copyright &copy; 2020 by <a class="white--text" href="https://preskilet.com">Preskilet</a>.</p>
                    <p class="ma-3 white--text text-center"> Made with ❤️ in India 🇮🇳 </p>
                </v-flex>
                
                <v-flex xs11 md2 class="text-center ma-3">
                    <a :href="item.link" v-for="item in SocialLinksOfPreskilet" :key="item.link" target="_blank">
                        <v-btn class="mx-4 white--text" icon router>
                            <v-icon size="24px">{{ item.icon }}</v-icon>
                        </v-btn>
                    </a>
                </v-flex>
            
            </v-layout>
        
        </div>
    
    </footer>

</div>
</template>

<style scoped>

.sticky{
position: relative;
}



.RightSideAd{
    padding-left: 0px;
    padding-right: 0px;
}

/* for desktop */
@media screen and (min-width: 650px) {

    .footerad{
        width: 100%;
        height: 100%;
        margin:25px;
        margin-top: -3px;
        margin-bottom:30px;
    }
    .RightSideAd{
        padding-left: 4%;
        padding-right: 4%;
    }

    .sticky{
        position: -webkit-sticky;
        position: sticky;
        top: 4rem;
        z-index: 2;
        width: auto !important;
    }

    .sticky2{
        position: -webkit-sticky;
        position: sticky;
        top: 27rem;
        z-index: 2;
        width: auto !important;
        margin-top: 280%;
    }
}

.form-container {
  padding   : 20px;
}

.footerad{ 
    width: 100%;
    height: 100%;
    margin: 25px;
    margin-top: 0px;
    margin-bottom:30px;
}





/* For footer */
.site-footer {
  background-color: #26272b;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
}

.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}

.site-footer hr.small {
  margin: 20px 0;
}

.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}

</style>

<script>
import { mapGetters } from "vuex";
import LandUpInfo from "@/components/LandUpInfo";

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;


// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

export default {
    
    name: "HowToUse",
    
    components: { LandUpInfo },
    
    computed: { ...mapGetters({ userData: "User/userData" }) },
    
    mounted() {

        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("How To USe Page");

        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");

    },
    
    data() {
        
        return {

            cardFlatFlag    : true,
            cardOutlineFlag : false,
            
            SocialLinksOfPreskilet  : [
                {   icon : "mdi-linkedin",  link : "https://www.linkedin.com/in/preskilet-incorporation-1bb17a1b9/" },
                {   icon : "mdi-instagram", link : "https://www.instagram.com/preskilet/"                           }
            ],
            
            commonMenuitems: [
                {   title : "Terms & conditions",   route : "/termsandconditions",  icon : "mdi-file-document"  },
                {   title : "Privacy Policy",       route : "/privacypolicy",       icon : "mdi-lock"           },
                {   title : "About us",             route: "/aboutus",              icon : "mdi-information"    },
                {   title : "Contact us",           route: "/contactus",            icon : "mdi-phone"          }
            ]

        }
        
    },

};

</script>
