<template>
<div>
    
    <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
            <v-card-title class="headline">Are you sure ?</v-card-title>
            
            <v-card-actions>
                <v-spacer></v-spacer>
                
                <v-btn color="green darken-1" text @click="dialogDelete = false">No</v-btn>
                <v-btn color="green darken-1" text @click="deleteRequest">Yes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
    <v-container>
        
        <v-layout row wrap justify-space-around>
            
            <v-flex xs11 md6>

                 <v-card color="white" outlined class="ma-2 pa-2">
                        
                        <v-row>
                            <v-col cols="12" md="10">
                                <v-select
                                    v-model="groupsListSelectedNameTask"
                                    :items="groupsList"
                                    label="Select Group to see followers"
                                    data-vv-name="select"
                                    :rules="[(v) => !!v || 'Subject Group is required']"
                                    required
                                    item-text="name"
                                    item-value="id"
                                    @change="getGroupfollowers"
                                ></v-select>
                            </v-col>
                            
                            <!-- <v-col cols="12" md="2">
                                <v-btn class="ma-2" color="primary" depressed v-on:click="getGroupfollowers">
                                    <v-icon dark left>mdi-send</v-icon>Show
                                </v-btn> <br />
                            </v-col> -->
                        </v-row>
                    
                    </v-card>


                <v-list v-if="groupsList.length != 0" subheader two-line>
                   
                    
                    <v-subheader>Group Followers
                      
                      </v-subheader>
                </v-list>

                     
                <v-card
                    flat
                    v-if="groupsListSelectedNameTask === ''"
                    class="mx-auto"
                    max-width="400"
                >

                    <v-img class="white--text align-end" :src="emptyStateImage"></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Followers'"
                            ></v-card-title>

                            <v-card-subtitle v-text="emptyStateMessage"></v-card-subtitle>

                        </div>

                    </div>

                </v-card>                            


    
                <v-card
                    flat
                    v-if="followerList.length === 0 && groupsListSelectedNameTask != ''"
                    class="mx-auto"
                    max-width="400"
                >
                            <v-card-subtitle> <b> No followers for this Group </b>(Tell your students to follow this group so that they can upload Assignments for tasks created for this group.)</v-card-subtitle>


                </v-card>       
                                <v-list>
                     


                    <v-list-item v-for="item in followerList" :key="item.memberId">

                                <v-list-item-avatar>
           <v-img :src="item.membersProfilePhoto"></v-img>
        </v-list-item-avatar>


         <v-list-item-content>
          <v-list-item-title v-text="item.memberName"></v-list-item-title>
        </v-list-item-content>

                        
                        <v-list-item-action>
                            <v-btn icon @click="deleteRequestById(item.memberId)">
                                <v-icon color="primary">mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            
            </v-flex>
            
            <v-flex xs11 md4>

                <v-card 
                    :flat="cardFlatFlag"
                    :outlined="cardOutlineFlag"
                    class="mx-auto sticky"
                    v-if="false"
                >
            
                    <v-list-item two-line class="RightSideAd">
                        
                        <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                        
                        <v-list-item-content>
                            
                            <v-list-item-title>Google Ads</v-list-item-title>
                            
                            <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                        
                        </v-list-item-content>
                                    
                        <v-btn color="primary" size="35" tile large icon router >
                            <v-icon left> mdi-compass-outline</v-icon>
                        </v-btn>


                    </v-list-item>
                    
                        <!-- Group Followers Page - Right Ad -->
                        <div class="ad-container">
                            <Adsense
                                style="display:block"
                                data-ad-client="ca-pub-2789565594210548"
                                data-ad-slot="6788875603"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            ></Adsense>
                        </div>


                </v-card>


            </v-flex>

        </v-layout>
    </v-container>
</div>
</template>

<style scoped>
.sticky{
position: relative;
}



.RightSideAd{
    padding-left: 0px;
    padding-right: 0px;
}

/* for desktop */
@media screen and (min-width: 650px) {
.RightSideAd{
    padding-left: 4%;
    padding-right: 4%;
}

.sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 2;
    width: auto !important;
}

  .sticky2{
    position: -webkit-sticky;
    position: sticky;
    top: 27rem;
    z-index: 2;
    width: auto !important;
    margin-top: 280%;
}
}

.form-container {
  padding   : 20px;
}


</style>

<script>

import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;



// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

export default {
    
    name: "Followers",
    
    computed: { ...mapGetters({ userData: "User/userData" }) },
    
    mounted() {

        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("GroupFollowers Page");

        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");

        this.setYears();
        this.getGroups();
    },
    
    data() {
        
        return {

            cardFlatFlag    : true,
            cardOutlineFlag : false,

            disabledAccpet: true,
            followerList: [],
            valid: true,
            disabled : false,
            groupsListSelectedName : "",
            groupsListSelectedNameTask : "",
            dialogDelete: false,
            deleteRequestId : "",

            addGroupForm: false,
            taskName : "",
            taskNameRules:  [ 
                (v) => !!v || "Task Name is required",
                (v) => (v && v.length <= 50) || "Group Name must be less than 50 characters"
            ],
            
            taskDescription : "",
            taskDescriptionRules:  [ 
                (v) => !!v || "Task Description is required",
                (v) => (v && v.length <= 100) || "Group Description must be less than 100 characters"
            ],

            subjectName : "",
            subjectTerms: [],
            subjectNameRules : [ 
                (v) => !!v || "Subject Name is required",
                (v) => (v && v.length <= 50) || "Subject Name must be less than 50 characters"
            ],

            subjectType: "",
            subjectTypes: ["Theroy", "Lab"],

            academicYear: "",
            academicYears: ['First Year', 'Second Year', 'Third Year', 'Fourth Year'],

            departmentName: "",
            departmentTerms: [],
            departmentNameRules:[ 
                (v) => !!v || "Department Name is required",
                (v) => (v && v.length <= 50) || "Department Name must be less than 50 characters"
            ],

            divName: "",
            divTerms: [],
            divNameRules:[ 
                (v) => !!v || "Division Name is required",
                (v) => (v && v.length <= 50) || "Division Name must be less than 50 characters"
            ],
            
            batchName: "",
            batchTerms: [],
            batchNameRules:[ 
                (v) => !!v || "Batch Name is required",
                (v) => (v && v.length <= 50) || "Batch Name must be less than 50 characters"
            ],

            year: "",
            years: [],

            // Test data
            groupsList: [],
            emptyStateImage: utilities.emptyState.groupFollowers.image,
            emptyStateMessage: utilities.emptyState.groupFollowers.message
        };
        
    },

    methods: {
        
        setYears(){ var i = -1; while(i < 20){ this.years.push(new Date().getFullYear() + i); i++;}},
        
        validate() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.createTask();
            }
        },
        
        reset() { this.$refs.form.reset() },


        acceptAllGroupRequest(){
            let data = {
                groupId : this.groupsListSelectedNameTask,
                followersIdArray: []
            }

            data.followersIdArray = this.followerList.map((follower)=>{
                return follower.userId._id;

            })

            console.log("accpet : ",data);
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis[apis.env].acceptAllRequest;
            
            axios(endPoint, {
                method  : "post",
                data: data,
                withCredentials: true,
                headers: { authorization: this.userData.token },
            })
            .then((res) => {
                
                console.log("success : ", res);
                this.$store.commit("User/toggleProgressLoader", false);
                this.$store.commit("User/showSuccessAlert", res.data.result.message);
                this.getGroupfollowers();
            })
            .catch((err) => {
                this.$store.commit("User/toggleProgressLoader", false);
                console.log(err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            });

        },

        deleteRequestById(groupId){
            this.deleteRequestId = groupId;
            this.dialogDelete = true;
        },

        getGroups(){
            
            let endPoint = apis[apis.env].getGroups;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                console.log("Groups data : ",res);
                
                let data = res.data.result.data;
                this.groupsList = data;
                this.$store.commit("User/toggleProgressLoader", false);

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Groups Displayed");
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in Following",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        deleteRequest(){

            console.log("delete group follower id :",this.deleteRequestId );
            
            this.dialogDelete = false;
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis[apis.env].removeFromGroup ;

            let data = {groupId : this.groupsListSelectedNameTask,
                            memberId : this.deleteRequestId}
            
            
            axios(endPoint, {
                method  : "post",
                data: data,
                withCredentials: true,
                headers: { authorization: this.userData.token },
            })
            .then((res) => {
                
                console.log("success : ", res);
                this.$store.commit("User/toggleProgressLoader", false);
                this.$store.commit("User/showSuccessAlert", "Follower Removed Succesfully !");
                this.getGroupfollowers();

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Group Follower Request Deleted");

            })
            .catch((err) => {
                this.$store.commit("User/toggleProgressLoader", false);
                console.log(err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            });
        },

        getGroupfollowers(){
            console.log("Group Selectd Name : ",this.groupsListSelectedNameTask);
            
            let endPoint = apis[apis.env].getGroupfollowers;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                params          : { groupId : this.groupsListSelectedNameTask },
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                console.log("Tasks reqest data : ",res);
                
                let data = res.data.result.data;
                this.followerList = data;
                this.$store.commit("User/toggleProgressLoader", false);

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Group Followers Displayed");
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in Following",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        createTask(){
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let data = {
                name        :   this.taskName,
                groupId   :   this.groupsListSelectedName,
                description :   this.taskDescription
            };
            
            console.log("Tasks  ", data);
            
            let endPoint = apis[apis.env].createTask;
            
            axios(endPoint, {
                method          : "post",
                data,
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                
                console.log("success : ", res.data.result.message);
                this.$store.commit("User/toggleProgressLoader", false);
                this.$store.commit("User/showSuccessAlert", res.data.result.message);
                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Group Task Created");
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                (this.disabled = false), console.log(err);
                let errorMsg = utilities.errorHandler(err);
                console.log("Error in Pret",err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        }
    }

};
</script>
