<template>
    <button @click="exportExcel">
        <slot></slot>
    </button>
</template>


<script>
    import XLSX from 'xlsx/xlsx';
    window.$ = window.jQuery = require('jquery');

    export default {
        name: "vue-excel-xlsx",

        props: {
            columns: {
                type: Array,
                default: []
            },
            sheetrows: {
                type: Array,
                default: []
            },
            filename: {
                type: String,
                default: 'excel'
            },
            sheetname: {
                type: String,
                default: 'SheetName'
            },
            
        },

        methods: {
            exportExcel() {
                let createXLSLFormatObj = [];
                let newXlsHeader = [];
                let vm = this;
                console.log("This in Excel Data : ", this);
                console.log("This in Excel Data : ", this.sheetrows);
                if (vm.columns.length === 0){
                    console.log("Add columns!");
                    return;
                }
                
                if (vm.sheetrows.length === 0){
                    console.log("Add sheetrows!");
                    return;
                }
                $.each(vm.columns, function(index, value) {
                    newXlsHeader.push(value.label);
                });

                createXLSLFormatObj.push(newXlsHeader);
                $.each(vm.sheetrows, function(index, value) {
                    let innerRowData = [];
                    $.each(vm.columns, function(index, val) {
                        if (val.dataFormat && typeof val.dataFormat === 'function') {
                            innerRowData.push(val.dataFormat(value[val.field]));
                        }else {
                            innerRowData.push(value[val.field]);
                        }
                    });
                    createXLSLFormatObj.push(innerRowData);
                });

                let filename = vm.filename + ".xlsx";

                let ws_name = vm.sheetname;

                let wb = XLSX.utils.book_new(),
                    ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                XLSX.utils.book_append_sheet(wb, ws, ws_name);
                XLSX.writeFile(wb, filename);
            
            
            
            
            
            
            }
        }
    }
</script>