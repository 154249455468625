<template>
<div>
    
    <v-container>
        
        <v-layout row wrap justify-space-around>

            <v-flex xs11 md6>

                <v-text-field
                    type="search"
                    v-model="searchQuery"
                    label="Search Video Profile by Names"
                    required
                    v-on:keyup.enter="pressEnter"
                    solo
                    append-icon="mdi-magnify"
                ></v-text-field>

                <v-card
                    v-for="item in searchResults"
                    :key="item._id"
                    flat
                    class="mx-auto "
                >
                    
                    <router-link
                    style="text-decoration: none;color:black;"
                    :to="'/' + item._id"
                    >

                        <v-list two-line>

                            <v-list-item>

                                <v-list-item-avatar size="50">
                                    <v-img v-if="item.profilePhoto" :src="item.profilePhoto"></v-img>
                                    <v-img v-if="!item.profilePhoto" src="https://media.istockphoto.com/vectors/user-member-vector-icon-for-ui-user-interface-or-profile-face-avatar-vector-id1130884625?k=20&m=1130884625&s=612x612&w=0&h=OITK5Otm_lRj7Cx8mBhm7NtLTEHvp6v3XnZFLZmuB9o="></v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>

                                    <v-list-item-title
                                    v-html="item.name"
                                    ></v-list-item-title>

                                    <v-list-item-subtitle
                                    >click to join groups created by this person</v-list-item-subtitle>

                                </v-list-item-content>

                            </v-list-item>

                            <v-divider inset></v-divider>

                        </v-list>

                    </router-link>

                </v-card>

                <v-flex xs12 md8 v-if="searchResultsNotFound" justify-space-around>

                    <v-img
                    src="https://thumbs.gfycat.com/FrigidMinorHorseshoecrab-size_restricted.gif"
                    ></v-img>
                    <p style="padding: 5%;">No Results Found !</p>

                </v-flex>
            
            </v-flex>
            
            <v-flex xs11 md4>
   
                <v-card 
                    :flat="cardFlatFlag"
                    :outlined="cardOutlineFlag"
                    class="mx-auto sticky"
                    v-if="false"
                >
            
                    <v-list-item two-line class="RightSideAd">
                        
                        <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                        
                        <v-list-item-content>
                            
                            <v-list-item-title>Google Ads</v-list-item-title>
                            
                            <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                        
                        </v-list-item-content>
                                    
                        <v-btn color="primary" size="35" tile large icon router >
                            <v-icon left> mdi-compass-outline</v-icon>
                        </v-btn>


                    </v-list-item>
                    
                        <!-- Search Page - Right Ad -->
                        <div class="ad-container">
                            <Adsense
                                style="display:block"
                                data-ad-client="ca-pub-2789565594210548"
                                data-ad-slot="3030605753"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            ></Adsense>
                        </div>
        
                </v-card>
              
            </v-flex>

        </v-layout>

    </v-container>

</div>
</template>

<style scoped>

.form-container {
  padding   : 20px;
}

</style>

<script>

import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

export default {
    
    name        : "Search",
    
    computed    : { ...mapGetters({ userData: "User/userData" }) },

    mounted() {

         if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }


        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Search Page");

        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
        this.$store.commit("User/toggleProgressLoader", false);
    
        if (this.searchQuery == undefined) {
            this.$store.commit("User/toggleProgressLoader", false);
        } else {
            
            let endPoint = apis[apis.env].search + "?q=" + this.searchQuery;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {

                console.log("serach result from back : ", res.data.result.serachResult);
                
                this.$store.commit("User/toggleProgressLoader", false);
                let data = res.data.result.serachResult;
                this.searchResults = data;
                
                if (this.searchResults.length == 0) {
                    this.searchResultsNotFound = true;
                }
                
                console.log("data for email ", data);
                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Search Results Displayed");
            
            })
            .catch((err) => {
          
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in Search",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });
        
        }

    },

    data() {
        
        return {
              cardFlatFlag    : true,
            cardOutlineFlag : false,
            
            searchResults           : [],
            searchResultsNotFound   : false,
            searchQuery             : this.$route.params.q,
            listOfskills            : utilities.listOfskills
        
        };

    },

    methods: {

        pressEnter: function() {
            
            window.location.href = "/search/" + this.searchQuery;
        }

    }
    
};
</script>
