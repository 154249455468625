<template>
<div>
    
    <v-container>
        
        <v-layout row wrap justify-space-around>

            <v-flex xs11 md6>

                <v-card color="white" outlined>

                    <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="form-container"
                    >

                        <v-text-field
                            v-model="title"
                            counter="30"
                            :rules="titleRules"
                            label="Title"
                            required
                            :disabled="disabled"
                            clearable
                        ></v-text-field>

                        <v-textarea
                            :rules="descriptionRules"
                            v-model="description"
                            auto-grow
                            clearable
                            counter="500"
                            row-height="1"
                            label="Description of the video"
                            required
                            :disabled="disabled"
                        ></v-textarea>

                        <br />

                        <v-img
                            v-if="previewThumbnailImg != ''"
                            :src="previewThumbnailImg"
                        ></v-img>

                        <!-- <v-file-input
                            :disabled="disabled"
                            @change="previewThumbnail"
                            accept="image/*"
                            required
                            id="thumbnailInput"
                            :rules="FileRules"
                            prepend-icon="mdi-camera"
                            label="Select a Thumbnail"
                        >
                        </v-file-input> -->

                        <!-- <video
                            height="165"
                            style="width: 100%;"
                            autoplay
                            muted
                            loop
                            controls
                            :src="previewblob"
                            alt="please reselect the video"
                        ></video> -->

                        <v-file-input
                            :disabled="disabled"
                            @change="preview"
                            
                            required
                            id="fileinput"
                            :rules="FileRules"
                            prepend-icon="assignment"
                            label="Select a file"
                        >
                        </v-file-input>


                         <v-select
                            :disabled="disabled"
                            v-model="groupsListSelectedName"
                            :items="groupsList"
                            label="Select Group"
                            data-vv-name="select"
                            :rules="[(v) => !!v || 'Subject Group is required']"
                            required
                            item-text="groupId.name"
                            item-value="groupId._id"
                            @change="getTaskList"
                        ></v-select>


                         <v-select
                            :disabled="disabled"
                            v-if="taskVisibility"
                            v-model="requestListSelectedName"
                            :items="requestList"
                            label="Select Task"
                            data-vv-name="select"
                            :rules="[(v) => !!v || 'Subject Group is required']"
                            required
                            item-text="name"
                            item-value="assignmentId"
                        ></v-select>

                        <br />

                        <p v-if="thumbnailProgressVisibility">
                            Uploading Thumbnail : {{ thumbanailProgressBar }} %
                        </p>

                        <v-progress-linear
                            color="light-blue"
                            height="10"
                            :value="thumbanailProgressBar"
                            striped
                            v-if="thumbnailProgressVisibility"
                        ></v-progress-linear>

                        <br />

                        <p v-if="progressvisibility">
                            Uploading Assignment : {{ progressbar }} %
                        </p>

                        <v-progress-linear
                            color="light-blue"
                            height="10"
                            :value="progressbar"
                            striped
                            v-if="progressvisibility"
                        ></v-progress-linear>

                        <br />

                        <div class="text-right">

                            <v-btn
                            class="ma-2"
                            color="primary"
                            depressed
                            v-on:click="validate"
                            :disabled="disabled"
                            >

                                <v-icon dark left>mdi-checkbox-marked-circle</v-icon>Upload

                            </v-btn>

                        </div>

                    </v-form>

                </v-card>

            </v-flex>

            <v-flex xs11 md4>

                <v-card 
                    :flat="cardFlatFlag"
                    :outlined="cardOutlineFlag"
                    class="mx-auto sticky"
                    v-if="false"
                >
            
                    <v-list-item two-line class="RightSideAd">
                        
                        <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                        
                        <v-list-item-content>
                            
                            <v-list-item-title>Google Ads</v-list-item-title>
                            
                            <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                        
                        </v-list-item-content>
                                    
                        <v-btn color="primary" size="35" tile large icon router >
                            <v-icon left> mdi-compass-outline</v-icon>
                        </v-btn>


                    </v-list-item>
                    
                        <!-- Pret Page - Right Ad -->
                        <div class="ad-container">
                            <Adsense
                                style="display:block"
                                data-ad-client="ca-pub-2789565594210548"
                                data-ad-slot="7895263089"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            ></Adsense>
                        </div>
    
                </v-card>
                
            </v-flex>

        </v-layout>

    </v-container>

</div>
</template>

<style scoped>
.sticky{
position: relative;
}



.RightSideAd{
    padding-left: 0px;
    padding-right: 0px;
}

/* for desktop */
@media screen and (min-width: 650px) {
.RightSideAd{
    padding-left: 4%;
    padding-right: 4%;
}

.sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 2;
    width: auto !important;
}

  .sticky2{
    position: -webkit-sticky;
    position: sticky;
    top: 27rem;
    z-index: 2;
    width: auto !important;
    margin-top: 280%;
}
}
.form-container {
  padding   : 20px;
}

</style>

<script>

import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

import "firebase/firebase-analytics";
var firebaseAnlytics;



// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

export default {
    
    name        : "Pret",
    
    computed    : { ...mapGetters({ userData    : "User/userData" }) },
  
    mounted() {

        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Pret Page");
        
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");

        this.getGroups();
    
    },
    
    
    data() {
        
        return {

            cardFlatFlag    : true,
            cardOutlineFlag : false,
            
            previewThumbnailImg         : "",
            thumbnailFile               : "",
            thumbnailName               : "",
            videoName                   : "",
            thumbnailDownloadURL        : "",
            videoFile                   : "",
            previewblob                 : "",
            profilePhoto                : "",
            progressbar                 : 0,
            thumbanailProgressBar       : 0,
            thumbnailProgressVisibility : false,
            progressvisibility          : false,
            url                         : "This is url",
            disabled                    : false,
            valid                       : true,
            
            title                       : "",
            titleRules                  : [ (v) => !!v || "Title is required", (v) => (v && v.length <= 50) || "Title must be less than 50 characters" ],
            
            description                 : "",
            descriptionRules            : [ (v) => !!v || "Description is required", (v) => { if (v.length <= 500) return true; return "description must be less than 500 characters"; } ],
            
            FileRules                   : [(v) => !!v || "File is required"],
            select                      : null,
            groupsList                  : [],
            groupsListSelectedName      : "",
            requestList                 : [],
            requestListSelectedName     : "",
            taskVisibility              : false,
            
        };

    },
    
    methods: {
        
        cancelFromEditing() {
            window.location.href = "/profile";
        },

        validate() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
              this.uploadVideo();
            }
        },

        reset() {

            this.$refs.form.reset();
            this.previewblob = "";
            this.previewThumbnailImg = "";
        
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },

        getGroups(){
            
            let endPoint = apis[apis.env].getFollowingGroups;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {

                console.log("Groups data : ",res);
                
                let data = res.data.result.data;
                this.groupsList = data.map((group)=> {
                  return {
                    groupId: {
                      name : group.name,
                      _id: group.groupId
                      }
                    }
                });
                // this.groupsList.push({groupId: {name : "None", _id:"1234567890"}})
                this.$store.commit("User/toggleProgressLoader", false);

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Groups Data Retrived");
            
            }) 
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in Following",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        getTaskList(){
            console.log("Group Selectd Name : ",this.groupsListSelectedName);

            if (this.groupsListSelectedName === "1234567890") {
               this.taskVisibility = false;
               this.requestListSelectedName = "something"
            } else {
                this.taskVisibility = true;
                let endPoint = apis[apis.env].getTasksList;
                this.$store.commit("User/toggleProgressLoader", true);
                
                axios(endPoint, {
                    method          : "get",
                    params          : { groupId : this.groupsListSelectedName },
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
                    console.log("Tasks reqest data : ",res);
                    
                    let data = res.data.result.data;
                    this.requestList = data;
                    this.$store.commit("User/toggleProgressLoader", false);

                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Tasks Data Retrived");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in Following",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

            }
            


        },

        previewThumbnail(event) {
            
            var fileSize = event.size / 1024 / 1024;
            if (fileSize >= 100) {
                
                this.previewThumbnailImg = "";
                this.$store.commit("User/showErrorAlert", "Thumbnail size is greater than 100 MB" );
            
            } else {
                
                this.thumbnailFile = event;
                var that = this;
                var reader = new FileReader();
                
                reader.onload = function(event) {
                    that.previewThumbnailImg = event.target.result.toString();
                };

                reader.readAsDataURL(event);
            
            }

        },

        preview(event) {
        
            var fileSize = event.size / 1024 / 1024;
            
            if (fileSize >= 1000) {
            
                this.previewblob = "";
                this.$store.commit("User/showErrorAlert", "Video size is greater than 1000 MB");

            } else {
                
                this.videoFile = event;
                var that = this;
                var reader = new FileReader();
                
                reader.onload = function(event) {
                    that.previewblob = event.target.result.toString();
                };
                
                reader.readAsDataURL(event);
            }
        },

        makePret(downloadURL, finalName) {
            
            console.log("Now making pret by downloadURL : ", downloadURL);
            console.log("Now making pret by newname : ", finalName);
            this.url = downloadURL;
            this.$store.commit("User/toggleProgressLoader", false);

            // @dhanesh old data schema

            // let data = {
            //     title           : this.title,
            //     description     : this.description,
            //     url             : this.url,
            //     firebaseName    : finalName,
            //     // thumbnailName   : this.thumbnailName,
            //     // thumbnailUrl    : this.thumbnailDownloadURL,
            //     // relativeThumbnailUrl : this.thumbnailName,
            //     relativeVideoUrl : this.videoName,
            // };

              let data = {
                "assignmentId": this.requestListSelectedName,
                "studentNote": "no notes now",
                "attachments": [this.url]
            };
            

            // if (this.groupsListSelectedName != "1234567890") {
            //     // endPoint = apis[apis.env].taskPret;
            //     data.taskId = this.requestListSelectedName
            // }

            console.log("data for submission : ", data)

            let endPoint = apis[apis.env].uploadAssignment;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "post",
                data,
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {

                console.log("success : ", res.data.result.message);
                this.$store.commit("User/toggleProgressLoader", false);
                this.disabled = false;
                this.$store.commit("User/showSuccessAlert", "Assignment submitted successfully.");
                this.reset();

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("New Pret Uploaded");
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                (this.disabled = false), console.log(err);
                let errorMsg = utilities.errorHandler(err);
                console.log("Error in Pret",err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        // uploadThumbnail() {
        
        //     this.disabled = true;
        //     this.thumbnailProgressVisibility = true;
        //     console.log("Thumbnail uploading started...");
        //     this.uploadImageAsPromise(this.thumbnailFile);
        
        // },

        uploadVideo() {
            
            (this.progressvisibility = true),
            this.$store.commit("User/toggleProgressLoader", false);

            console.log("Video uploading started...");
            this.uploadVideoAsPromise(this.videoFile);
        
        },

        // uploadImageAsPromise(thumbnailFile) {
            
        //     var newName        = " | Real Title : " + this.title  + " | Date : " + new Date(Date.now()).toUTCString();
        //     var newFolderName  = " | Real Title : " + this.title     + " | Date : " + new Date(Date.now()).toUTCString(); 
        //     var finalName   = "/prets/" + this.userData.email + "/" + newFolderName + "/" + newName;
        //     this.thumbnailName  = finalName;
        //     var storageRef      = firebase.storage().ref(finalName);
        //     var uploadTask      = storageRef.put(thumbnailFile);

        //     uploadTask.on("state_changed", (snapshot) => {
                
        //         var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //         this.thumbanailProgressBar = parseInt(progress);
                
        //         if (progress == 100) {
                
        //             this.thumbnailProgressVisibility = false;
        //             this.$store.commit("User/toggleProgressLoader", true);
                
        //         }
                
        //         switch (snapshot.state) {
                
        //             case firebase.storage.TaskState.PAUSED:
        //             console.log("Upload is paused");
        //             break;

        //             case firebase.storage.TaskState.RUNNING:
        //             console.log("Upload is running");
        //             break;
                
        //         }
        //     },

        //     function(error) {}, () => {
                
        //         uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        
        //             this.thumbnailDownloadURL = downloadURL;
        //             this.uploadVideo(newFolderName);
                
        //         });

        //     });

        // },

        uploadVideoAsPromise(videoFile) {

            var newName     = " | Real Title : " + this.title +" | Date : " + new Date(Date.now()).toUTCString();
            var newFolderName  = " | Real Title : " + this.title     + " | Date : " + new Date(Date.now()).toUTCString(); 
            var finalName   = "/prets/" + this.userData.email + "/" + newFolderName + "/" + newName;
            this.videoName  = finalName;
            var storageRef  = firebase.storage().ref(finalName);
            var uploadTask  = storageRef.put(videoFile);

            uploadTask.on("state_changed", (snapshot) => {
                
                var progress        = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.progressbar    = parseInt(progress);
                
                if (progress == 100) {

                    this.progressvisibility = false;
                    this.$store.commit("User/toggleProgressLoader", true);
                
                }
                
                switch (snapshot.state) {
                
                    case firebase.storage.TaskState.PAUSED:
                    console.log("Upload is paused");
                    break;

                    case firebase.storage.TaskState.RUNNING:
                    console.log("Upload is running");
                    break;
                }

            },
            function(error) {}, () => {
                
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    this.makePret(downloadURL, finalName);
                });

            });

        }

    }

};
</script>
