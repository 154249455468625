let apis = {
  env   : "dev",

  local : {
    vapidKey            : "BOiL1dkWbiWfULUo2FtCl3WHo052uWthFkeMyJTmQVFcDnVndXfrGf6z_ABHYjfawYzzX448V9LTc23fABDc2fA",

    firebaseConfig: {

      apiKey            : "AIzaSyD4epbaYSLvOYq-uzPlsf7txLdPw9cfiTo",
      authDomain        : "preskiletdev.firebaseapp.com",
      databaseURL       : "https://preskiletdev.firebaseio.com",
      projectId         : "preskiletdev",
      storageBucket     : "preskiletdev.appspot.com",
      messagingSenderId : "910345115417",
      appId             : "1:910345115417:web:f14d32b4d8f9a890b71788",
      measurementId     : "G-77H4WZYLRL"
    
    },

    //nestimatic - @dhanesh
    getCollegeList : "http://localhost:3000/api/users/colleges",
    getTeacherList: "http://localhost:3000/api/users/teachers",
    getGroupList: "http://localhost:3000/api/teacher/groups",
    checkTeacherRegistration: "http://localhost:3000/api/college/teacher",
    setAccountType: "http://localhost:3000/api/user/account",
    getFollowingGroups      : "http://localhost:3000/api/member/groups",
    followGroup                  : "http://localhost:3000/api/groups/request/create",
    uploadAssignment: "http://localhost:3000/api/assignments/submission",


    //nestimatic - @shoiab
    getGroupRequestsList    : "http://localhost:3000/api/groups/requests",
    acceptAllRequest        : "http://localhost:3000/api/groups/request/action",
    getGroupfollowers       : "http://localhost:3000/api/groups/members",
    removeFromGroup         : "http://localhost:3000/api/groups/member/delete",
    createTask              : "http://localhost:3000/api/group/assignments/create",
    getTasksList            : "http://localhost:3000/api/group/assignments",
    getTaskVideos           : "http://localhost:3000/api/assignments/submissions",
    evaluateAssignment      : "http://localhost:3000/api/assignments/submission/evaluate",
    markAttendance          : "http://localhost:3000/api/attendance/create",
    absentEmail             : "http://localhost:3000/api/attendance/parent/email",

    // signin and signout
    signInUser              : "http://localhost:3000/api/signin",
    signOut                 : "http://localhost:3000/api/signout",

    // serach and Preskilet
    search                  : "http://localhost:3000/api/search",
    preskilet               : "http://localhost:3000/api/user/preskilet",
    blankPreskilet          : "http://localhost:3000/api/user/blank/preskilet",

    //make a pret
    pret                    : "http://localhost:3000/api/pret",
    taskPret                : "http://localhost:3000/api/task/pret",
    deletePret              : "http://localhost:3000/api/deletepret",

    //profile
    getProfile              : "http://localhost:3000/api/user/profile",
    editProfile             : "http://localhost:3000/api/user/profile",
    follow                  : "http://localhost:3000/api/follow",
    // followGroup             : "http://localhost:3000/api/group/follow",
    unFollowGroup           : "http://localhost:3000/api/group/unfollow",
    getGroupRequests        : "http://localhost:3000/api/group/requests",

    unfollow                : "http://localhost:3000/api/unfollow",
    followers               : "http://localhost:3000/api/followers",
    // getGroupfollowers       : "http://localhost:3000/api/group/followers",

    following               : "http://localhost:3000/api/following",
    getFollowingGroupIds    : "http://localhost:3000/api/following/groups/ids",
    // getFollowingGroups      : "http://localhost:3000/api/following/groups",
    // getGroupRequestsList    : "http://localhost:3000/api/group/requests",
    deleteRequest           : "http://localhost:3000/api/group/request/decline",
    // removeFromGroup         : "http://localhost:3000/api/group/remove/follower",
    // acceptAllRequest        : "http://localhost:3000/api/group/request/accept",

    //Groups
    createGroup             : "http://localhost:3000/api/groups/create",
    getGroups               : "http://localhost:3000/api/groups",
    deleteGroup             : "http://localhost:3000/api/group",
    getGroupExcelData       : "http://localhost:3000/api/group/excel",
    getAttendaceExcelData       : "http://localhost:3000/api/attendance/excel",

    //Tasks
    // createTask              : "http://localhost:3000/api/task",
    // getTasksList            : "http://localhost:3000/api/task/list",
    deleteTask              : "http://localhost:3000/api/task",
    // getTaskVideos           : "http://localhost:3000/api/task/videos",

    //Feed
    getFeed                 : "http://localhost:3000/api/video/feed",
    getSavedPret            : "http://localhost:3000/api/videos/saved",
    getSuggestions          : "http://localhost:3000/api/user/suggestions",

    // push notifications token
    saveNotificationToken   : "http://localhost:3000/api/notifications/token",

    //Explore Video section
    exploreVideos           : "http://localhost:3000/api/video/explore",
    trendingVideos          : "http://localhost:3000/api/video/trending",

    //watch section
    viewPret                : "http://localhost:3000/api/video/view",
    blankViewPret           : "http://localhost:3000/api/video/blankview",

    gradePret               : "http://localhost:3000/api/video/grade",

    savePret                : "http://localhost:3000/api/video/save",
    unSavePret              : "http://localhost:3000/api/video/unsave",

    getAvgGrades            : "http://localhost:3000/api/video/grade/average",
 
    likePret                : "http://localhost:3000/api/video/like",
    unLikePret              : "http://localhost:3000/api/video/unlike",
    commentPret             : "http://localhost:3000/api/video/comment",
    deleteComment           : "http://localhost:3000/api/video/comment",
    flagPret                : "http://localhost:3000/api/video/unfit",

    //recommendations
    recommendations         : "http://localhost:3000/api/video/recommendations",
  },

  dev: {
    vapidKey                : "BOiL1dkWbiWfULUo2FtCl3WHo052uWthFkeMyJTmQVFcDnVndXfrGf6z_ABHYjfawYzzX448V9LTc23fABDc2fA",

    firebaseConfig  : {

      apiKey            : "AIzaSyD4epbaYSLvOYq-uzPlsf7txLdPw9cfiTo",
      authDomain        : "preskiletdev.firebaseapp.com",
      databaseURL       : "https://preskiletdev.firebaseio.com",
      projectId         : "preskiletdev",
      storageBucket     : "preskiletdev.appspot.com",
      messagingSenderId : "910345115417",
      appId             : "1:910345115417:web:f14d32b4d8f9a890b71788",
      measurementId     : "G-77H4WZYLRL"

 
    },

    //nestimatic - @dhanesh
    getCollegeList : "https://preskiletdev.herokuapp.com/api/users/colleges",
    getTeacherList: "https://preskiletdev.herokuapp.com/api/users/teachers",
    getGroupList: "https://preskiletdev.herokuapp.com/api/teacher/groups",
    checkTeacherRegistration: "https://preskiletdev.herokuapp.com/api/college/teacher",
    setAccountType: "https://preskiletdev.herokuapp.com/api/user/account",
    getFollowingGroups      : "https://preskiletdev.herokuapp.com/api/member/groups",
    followGroup                  : "https://preskiletdev.herokuapp.com/api/groups/request/create",
    uploadAssignment: "https://preskiletdev.herokuapp.com/api/assignments/submission",


    //nestimatic - @shoiab
    getGroupRequestsList    : "https://preskiletdev.herokuapp.com/api/groups/requests",
    acceptAllRequest        : "https://preskiletdev.herokuapp.com/api/groups/request/action",
    getGroupfollowers       : "https://preskiletdev.herokuapp.com/api/groups/members",
    removeFromGroup         : "https://preskiletdev.herokuapp.com/api/groups/member/delete",
    createTask              : "https://preskiletdev.herokuapp.com/api/group/assignments/create",
    getTasksList            : "https://preskiletdev.herokuapp.com/api/group/assignments",
    getTaskVideos           : "https://preskiletdev.herokuapp.com/api/assignments/submissions",
    evaluateAssignment      : "https://preskiletdev.herokuapp.com/api/assignments/submission/evaluate",
    markAttendance          : "https://preskiletdev.herokuapp.com/api/attendance/create",
    absentEmail             : "https://preskiletdev.herokuapp.com/api/attendance/parent/email",
    

    // signin and signout
    signInUser              : "https://preskiletdev.herokuapp.com/api/signin",
    signOut                 : "https://preskiletdev.herokuapp.com/api/signout",

    // serach and Preskilet
    search                  : "https://preskiletdev.herokuapp.com/api/search",
    preskilet               : "https://preskiletdev.herokuapp.com/api/user/preskilet",
    blankPreskilet          : "https://preskiletdev.herokuapp.com/api/user/blank/preskilet",

    //make a pret
    pret                    : "https://preskiletdev.herokuapp.com/api/pret",
    taskPret                : "https://preskiletdev.herokuapp.com/api/task/pret",
    deletePret              : "https://preskiletdev.herokuapp.com/api/deletepret",

    //profile
    getProfile              : "https://preskiletdev.herokuapp.com/api/user/profile",
    editProfile             : "https://preskiletdev.herokuapp.com/api/user/profile",
    follow                  : "https://preskiletdev.herokuapp.com/api/follow",
    // followGroup             : "https://preskiletdev.herokuapp.com/api/group/follow",
    unFollowGroup           : "https://preskiletdev.herokuapp.com/api/group/unfollow",
    getGroupRequests        : "https://preskiletdev.herokuapp.com/api/group/requests",

    unfollow                : "https://preskiletdev.herokuapp.com/api/unfollow",
    followers               : "https://preskiletdev.herokuapp.com/api/followers",
    // getGroupfollowers       : "https://preskiletdev.herokuapp.com/api/group/followers",

    following               : "https://preskiletdev.herokuapp.com/api/following",
    getFollowingGroupIds    : "https://preskiletdev.herokuapp.com/api/following/groups/ids",
    // getFollowingGroups      : "https://preskiletdev.herokuapp.com/api/following/groups",
    // getGroupRequestsList    : "https://preskiletdev.herokuapp.com/api/group/requests",
    deleteRequest           : "https://preskiletdev.herokuapp.com/api/group/request/decline",
    // removeFromGroup         : "https://preskiletdev.herokuapp.com/api/group/remove/follower",
    // acceptAllRequest        : "https://preskiletdev.herokuapp.com/api/group/request/accept",

    //Groups
    createGroup             : "https://preskiletdev.herokuapp.com/api/groups/create",
    getGroups               : "https://preskiletdev.herokuapp.com/api/groups",
    deleteGroup             : "https://preskiletdev.herokuapp.com/api/group",
    getGroupExcelData       : "https://preskiletdev.herokuapp.com/api/group/excel",
    getAttendaceExcelData       : "https://preskiletdev.herokuapp.com/api/attendance/excel",

    //Tasks
    // createTask              : "https://preskiletdev.herokuapp.com/api/task",
    // getTasksList            : "https://preskiletdev.herokuapp.com/api/task/list",
    deleteTask              : "https://preskiletdev.herokuapp.com/api/task",
    // getTaskVideos           : "https://preskiletdev.herokuapp.com/api/task/videos",

    //Feed
    getFeed                 : "https://preskiletdev.herokuapp.com/api/video/feed",
    getSavedPret            : "https://preskiletdev.herokuapp.com/api/videos/saved",
    getSuggestions          : "https://preskiletdev.herokuapp.com/api/user/suggestions",

    // push notifications token
    saveNotificationToken   : "https://preskiletdev.herokuapp.com/api/notifications/token",

    //Explore Video section
    exploreVideos           : "https://preskiletdev.herokuapp.com/api/video/explore",
    trendingVideos          : "https://preskiletdev.herokuapp.com/api/video/trending",

    //watch section
    viewPret                : "https://preskiletdev.herokuapp.com/api/video/view",
    blankViewPret           : "https://preskiletdev.herokuapp.com/api/video/blankview",

    gradePret               : "https://preskiletdev.herokuapp.com/api/video/grade",

    savePret                : "https://preskiletdev.herokuapp.com/api/video/save",
    unSavePret              : "https://preskiletdev.herokuapp.com/api/video/unsave",

    getAvgGrades            : "https://preskiletdev.herokuapp.com/api/video/grade/average",
 
    likePret                : "https://preskiletdev.herokuapp.com/api/video/like",
    unLikePret              : "https://preskiletdev.herokuapp.com/api/video/unlike",
    commentPret             : "https://preskiletdev.herokuapp.com/api/video/comment",
    deleteComment           : "https://preskiletdev.herokuapp.com/api/video/comment",
    flagPret                : "https://preskiletdev.herokuapp.com/api/video/unfit",

    //recommendations
    recommendations         : "https://preskiletdev.herokuapp.com/api/video/recommendations",
  },

  prod: {
    vapidKey                : "NEEDS_TO_BE_CONFIGURED",

    firebaseConfig          : "NEEDS_TO_BE_CONFIGURED",

    //nestimatic - @dhanesh
    getCollegeList : "https://preskilet.herokuapp.com/api/users/colleges",
    getTeacherList: "https://preskilet.herokuapp.com/api/users/teachers",
    getGroupList: "https://preskilet.herokuapp.com/api/teacher/groups",
    checkTeacherRegistration: "https://preskilet.herokuapp.com/api/college/teacher",
    setAccountType: "https://preskilet.herokuapp.com/api/user/account",
    getFollowingGroups      : "https://preskilet.herokuapp.com/api/member/groups",
    followGroup                  : "https://preskilet.herokuapp.com/api/groups/request/create",
    uploadAssignment: "https://preskilet.herokuapp.com/api/assignments/submission",


    //nestimatic - @shoiab
    getGroupRequestsList    : "https://preskilet.herokuapp.com/api/groups/requests",
    acceptAllRequest        : "https://preskilet.herokuapp.com/api/groups/request/action",
    getGroupfollowers       : "https://preskilet.herokuapp.com/api/groups/members",
    removeFromGroup         : "https://preskilet.herokuapp.com/api/groups/member/delete",
    createTask              : "https://preskilet.herokuapp.com/api/group/assignments/create",
    getTasksList            : "https://preskilet.herokuapp.com/api/group/assignments",
    getTaskVideos           : "https://preskilet.herokuapp.com/api/assignments/submissions",
    evaluateAssignment      : "https://preskilet.herokuapp.com/api/assignments/submission/evaluate",
    markAttendance          : "https://preskilet.herokuapp.com/api/attendance/create",
    absentEmail             : "https://preskilet.herokuapp.com/api/attendance/parent/email",

    // signin and signout
    signInUser              : "https://preskilet.herokuapp.com/api/signin",
    signOut                 : "https://preskilet.herokuapp.com/api/signout",

    // serach and Preskilet
    search                  : "https://preskilet.herokuapp.com/api/search",
    preskilet               : "https://preskilet.herokuapp.com/api/user/preskilet",
    blankPreskilet          : "https://preskilet.herokuapp.com/api/user/blank/preskilet",

    //make a pret
    pret                    : "https://preskilet.herokuapp.com/api/pret",
    taskPret                : "https://preskilet.herokuapp.com/api/task/pret",
    deletePret              : "https://preskilet.herokuapp.com/api/deletepret",

    //profile
    getProfile              : "https://preskilet.herokuapp.com/api/user/profile",
    editProfile             : "https://preskilet.herokuapp.com/api/user/profile",
    follow                  : "https://preskilet.herokuapp.com/api/follow",
    // followGroup             : "https://preskilet.herokuapp.com/api/group/follow",
    unFollowGroup           : "https://preskilet.herokuapp.com/api/group/unfollow",
    getGroupRequests        : "https://preskilet.herokuapp.com/api/group/requests",


    unfollow                : "https://preskilet.herokuapp.com/api/unfollow",
    followers               : "https://preskilet.herokuapp.com/api/followers",
    // getGroupfollowers       : "https://preskilet.herokuapp.com/api/group/followers",

    following               : "https://preskilet.herokuapp.com/api/following",
    getFollowingGroupIds    : "https://preskilet.herokuapp.com/api/following/groups/ids",
    // getFollowingGroups      : "https://preskilet.herokuapp.com/api/following/groups",
    // getGroupRequestsList    : "https://preskilet.herokuapp.com/api/group/requests",
    deleteRequest           : "https://preskilet.herokuapp.com/api/group/request/decline",
    // removeFromGroup         : "https://preskilet.herokuapp.com/api/group/remove/follower",
    // acceptAllRequest        : "https://preskilet.herokuapp.com/api/group/request/accept",


    //Groups
    createGroup             : "https://preskilet.herokuapp.com/api/group",
    getGroups               : "https://preskilet.herokuapp.com/api/groups",
    deleteGroup             : "https://preskilet.herokuapp.com/api/group",
    getGroupExcelData       : "https://preskilet.herokuapp.com/api/group/excel",


    //Tasks
    // createTask              : "https://preskilet.herokuapp.com/api/task",
    // getTasksList            : "https://preskilet.herokuapp.com/api/task/list",
    deleteTask              : "https://preskilet.herokuapp.com/api/task",
    // getTaskVideos           : "https://preskilet.herokuapp.com/api/task/videos",

    
    //Feed
    getFeed                 : "https://preskilet.herokuapp.com/api/video/feed",
    getSavedPret            : "https://preskilet.herokuapp.com/api/videos/saved",
    getSuggestions          : "https://preskilet.herokuapp.com/api/user/suggestions",

    // push notifications token
    saveNotificationToken   : "https://preskilet.herokuapp.com/api/notifications/token",

    //Explore Video section
    exploreVideos           : "https://preskilet.herokuapp.com/api/video/explore",
    trendingVideos          : "https://preskilet.herokuapp.com/api/video/trending",


    //watch section
    viewPret                : "https://preskilet.herokuapp.com/api/video/view",
    blankViewPret           : "https://preskilet.herokuapp.com/api/video/blankview",

    gradePret               : "https://preskilet.herokuapp.com/api/video/grade", 

    savePret                : "https://preskilet.herokuapp.com/api/video/save",
    unSavePret              : "https://preskilet.herokuapp.com/api/video/unsave",

    getAvgGrades            : "https://preskilet.herokuapp.com/api/video/grade/average",

    likePret                : "https://preskilet.herokuapp.com/api/video/like",
    unLikePret              : "https://preskilet.herokuapp.com/api/video/unlike",
    commentPret             : "https://preskilet.herokuapp.com/api/video/comment",
    deleteComment           : "https://preskilet.herokuapp.com/api/video/comment",
    flagPret                : "https://preskilet.herokuapp.com/api/video/unfit",

    //recommendations
    recommendations         : "https://preskilet.herokuapp.com/api/video/recommendations"

  },
};

export default apis;
