<template>
<div>
    
    <v-container style="min-height: 80vh;">
        
        <v-layout row justify-space-around>
            
            <!-- Search Bar -->
            <!-- <v-flex xs11 md9 class="text-center" style="margin-bottom: 1%;">
            
                <v-text-field
                    v-model="searchQuery"
                    type="search"
                    v-on:keyup.enter="pressEnter"
                    solo
                    label="Search Video Profile by Names"
                    append-icon="mdi-magnify"
                    >
                </v-text-field>
                
            </v-flex> -->

            <!-- Let section which is on top on mobile phone-->
            <v-flex xs12 md5 hidden-md-and-up>

                <!-- !!! If user is not signed in -->
                
                <!-- Preskilet sign up button & Ad -->
                <v-flex xs12 md11 v-if="!userData.signedIn">
                    
                    <v-row dense class="pa-0 ma-0">
                        
                        <v-col>
                            
                            <v-card flat>
                                
                                <v-img class="hidden-md-and-up white--text align-end" :src="vectorImages.presentation"></v-img>
                                
                                <div class="d-flex flex-no-wrap justify-space-between">
                                    
                                    <div>
                                        <v-card-title class="headline" v-text="'Nestimatic Account'"></v-card-title>
                                        <v-card-subtitle v-text="'With an Preskilet account, you can make an elegant video resume. Your resume will be live at preskilet.com/your email all the time. You can then share this link with any recruiter. And the best part is that it’s all for free !'"></v-card-subtitle>
                                    </div>
                                    
                                    <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                                        <v-img style="border-radius: 10px;" :src="vectorImages.presentation"></v-img>
                                    </v-avatar>
                                
                                </div>
                            
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="ma-2" color="primary" dark router to="/signin"> Sign Up / Sign In <v-icon dark right>mdi-checkbox-marked-circle</v-icon> </v-btn>
                                </v-card-actions>
                            
                            </v-card>
                        
                        </v-col>
                        
                        <v-col>
                            <!--
                            <v-card flat>
                                <v-img max-width="500" class="white--text align-end" :src="vectorImages.homePageAnimtaion"></v-img>
                            </v-card>
                            -->
                        </v-col>
                    
                    </v-row>
                
                </v-flex>
                
                <!-- !!! If user is signed in -->

                <!-- notification and install pwa -->
                <v-flex xs12 md12 v-if="userData.signedIn">
                    
                    <v-row dense class="pa-0 ma-0">
                        
                        <v-col v-if="notificationPermission">

                            <v-card color="primary" dark>
                    <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title>
                        <v-icon left>mdi-bell</v-icon>
                        <span class="title">Allow Notifications to </span>
                        </v-card-title>

                        <!-- For Candidates -->
                        <v-card-text style="font-size: 18px;line-height: 150%;" class="white--text">
                        <ul>
                            <li> Get notifications  of <b> Task / Assignments </b> for your Group / Batch. </li>
                            <li >know when assignment is your <b> Graded </b> by teacher. </li>
                        </ul>
                        </v-card-text>
                        
                    </div>
                    
                    <v-avatar class="ma-3 hidden-sm-and-down" size="125"  style="border-radius: 10px;">
                        <v-img :src="vectorImages.notifications"></v-img>
                    </v-avatar>
                    </div>
                
                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn
                        class="ma-2 primary--text"
                        color="white"
                        dark
                        @click="reqforpermission()" > Allow
                        <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                        
                        <v-progress-circular
                        v-if="notificationprogress"
                        :size="50"
                        color="white"
                        dark
                        indeterminate>
                        </v-progress-circular>
                    </v-card-actions>
                </v-card>
                        
                        </v-col>
        
                    
                    </v-row>
                    
                </v-flex>
         
            </v-flex>


            <!-- Explore videos Feed -->
            <v-flex xs12 md6>

                <LandUpInfo />


              <v-flex xs12 md12 v-if="userData.signedIn">
                    
                    <v-row dense class="pa-0 ma-0">
                        
                        <v-col>

                            <v-card flat>
                    <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title>
                        <span class="title">Welcome to Nestimatic</span>
                        </v-card-title>

                        <!-- For Candidates -->
                        <v-card-text style="font-size: 18px;line-height: 150%;" >
                        <ul>
                            <li> Click on left menu to get started</li>
                            <li >If you are teacher you can create groups of students & take their assesment and attendance online</li>
                            <li >If you are student you can submit your Assignments online</li>
                        </ul>
                        </v-card-text>
                        
                    </div>
                    
                    <v-avatar class="ma-3 hidden-sm-and-down" size="125"  style="border-radius: 10px;">
                        <v-img :src="vectorImages.Welcome"></v-img>
                    </v-avatar>
                    </div>
                
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                        
                        </v-col>
        
                    
                    </v-row>
                    
                </v-flex>

                <!-- <ExploreVideos /> -->
            </v-flex>

            <!-- Right section only on desktop-->
            <v-flex xs12 md5 hidden-sm-and-down>

                <!-- !!! If user is not signed in -->
               
                <!-- Preskilet sign up button & Ad -->
                <v-flex xs12 md11 v-if="!userData.signedIn">
                    
                    <v-row dense class="pa-0 ma-0">
                        
                        <v-col>
                            
                            <v-card flat>
                                
                                <v-img class="hidden-md-and-up white--text align-end" :src="vectorImages.presentation"></v-img>
                                
                                <div class="d-flex flex-no-wrap justify-space-between">
                                    
                                    <div>
                                        <v-card-title class="headline" v-text="'Nestimatic Account'"></v-card-title>
                                        <v-card-subtitle v-text="'With an Nestimatic account, you can take assignment submmissions online. And the best part is that it’s all for free !'"></v-card-subtitle>
                                    </div>
                                    
                                    <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                                        <v-img style="border-radius: 10px;" :src="vectorImages.presentation"></v-img>
                                    </v-avatar>
                                
                                </div>
                            
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="ma-2" color="primary" dark router to="/signin"> Sign Up <v-icon dark right>mdi-checkbox-marked-circle</v-icon> </v-btn>
                                </v-card-actions>
                            
                            </v-card>
                        
                        </v-col>
                        
                        <v-col>
                        <!--       
                            <v-card flat >
                                <v-img max-width="500" class="white--text align-end" :src="vectorImages.homePageAnimtaion"></v-img>
                            </v-card>
                        -->
                        </v-col>
                    
                    </v-row>
                
                </v-flex>
                
                <!-- !!! If user is signed in -->

                <!-- notification and install pwa -->
                <v-flex xs12 md12 v-if="userData.signedIn">
                    
                    <v-row dense class="pa-0 ma-0">
                        
                        <v-col v-if="notificationPermission">

                            <v-card color="primary" dark>
                    <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title>
                        <v-icon left>mdi-bell</v-icon>
                        <span class="title">Allow Notifications to </span>
                        </v-card-title>

                        <!-- For Candidates -->
                        <v-card-text style="font-size: 18px;line-height: 150%;" class="white--text">
                        <ul>
                            <li> Get notifications  of <b> Task / Assignments </b> for your Group / Batch. </li>
                            <li >know when assignment is your <b> Graded </b> by teacher. </li>
                        </ul>
                        </v-card-text>
                        
                    </div>
                    
                    <v-avatar class="ma-3 hidden-sm-and-down" size="125"  style="border-radius: 10px;">
                        <v-img :src="vectorImages.notifications"></v-img>
                    </v-avatar>
                    </div>
                
                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn
                        class="ma-2 primary--text"
                        color="white"
                        dark
                        @click="reqforpermission()" > Allow
                        <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                        
                        <v-progress-circular
                        v-if="notificationprogress"
                        :size="50"
                        color="white"
                        dark
                        indeterminate>
                        </v-progress-circular>
                    </v-card-actions>
                </v-card>
                        
                        </v-col>
        
                    
                    </v-row>
                    
                </v-flex>
                <!-- Suggestions  -->
                <Suggestions v-if="userData.signedIn" />
         
            </v-flex>

        </v-layout>
    </v-container>
     <br>

</div>
</template>

<style scoped>

.footerad{ 
    width: 100%;
    height: 100%;
    margin: 25px;
    margin-top: 0px;
    margin-bottom:30px;
}

.Brand-logo {
  text-decoration: none !important;
  color: black !important;
}

.headtitle {
  font-size: 45px;
  color: grey;
  margin-bottom: 8%;
  margin-top: 10vh;
}


.blink_me {
  animation: blinker 1s linear infinite;
  color: red;
  margin: 1%;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* for desktop */
@media screen and (min-width: 650px) {
  .videoRecommendationThubmnail {
    border-radius: 7px;
  }
  .headtitle {
    font-size: 70px;
  }
  .footerad{
      width: 100%;
      height: 100%;
      margin:25px;
      margin-top: -3px;
      margin-bottom:30px;
  }
  .sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 35px;
    z-index: 2;
    width: auto !important;
}

  .sticky2{
    position: -webkit-sticky;
    position: sticky;
    top: 400px;
    z-index: 2;
    width: auto !important;
    margin-top: 280%;
}

}

/* For footer */
.site-footer {
  background-color: #26272b;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
}

.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}

.site-footer hr.small {
  margin: 20px 0;
}

.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}

</style>

<script>

import { mapGetters } from "vuex";

import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import "firebase/firebase-analytics";
import LandUpInfo from "@/components/LandUpInfo";

import VuePlyr from "vue-plyr";

import ExploreVideos from "@/components/ExploreVideos";
import Suggestions from "@/components/Suggestions";

var messaging;
var deferredPrompt;
var firebaseAnlytics;

// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)


export default {
    name: "Home",
    
    components: { "vue-plyr": VuePlyr, ExploreVideos, Suggestions, LandUpInfo },
    
    computed: { ...mapGetters({ userData: "User/userData" }) },
    
    async created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Home Page");
        
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
        
        if (firebase.messaging.isSupported()) { messaging = firebase.messaging() };
        
        if (Notification.permission == "granted") {
            this.notificationPermission = false;
            this.postNotificationToken();
        }
        
        this.exploreVideos();
        
        let that = this;
        
        window.addEventListener("beforeinstallprompt", function(e) {
            
            that.installPwaBanner = true;
            e.preventDefault();
            deferredPrompt = e;
        });
    
    },
    
    mounted() { this.getSuggestions() },
    
    data() {
        return {

            adsarray : [2,4,8,9,12,16],

            loadMoreVideosProgress  : false,
            excludeVideoIDs        : [],
            
            options                 : { controls: ["play-large", "mute"], hideControls: true},
            exploreVideosArray      : [],
            suggestData             : [],
            isLimitedLength         : true,
            
            notificationPermission  : true,
            notificationprogress    : false,
            installPwaBanner        : false,
            vectorImages            : utilities.vectorImages,
            searchQuery             : "",
            
            SocialLinksOfPreskilet  : [
                {   icon : "mdi-linkedin",  link : "https://www.linkedin.com/in/preskilet-incorporation-1bb17a1b9/" },
                {   icon : "mdi-instagram", link : "https://www.instagram.com/preskilet/"                           }
            ],
            
            commonMenuitems: [
                {   title : "Terms & conditions",   route : "/termsandconditions",  icon : "mdi-file-document"  },
                {   title : "Privacy Policy",       route : "/privacypolicy",       icon : "mdi-lock"           },
                {   title : "About us",             route: "/aboutus",              icon : "mdi-information"    },
                {   title : "Contact us",           route: "/contactus",            icon : "mdi-phone"          }
            ]
        }
    },
    
    methods: {
        
        exploreVideos() {

            this.loadMoreVideosProgress = true;

            let endPoint = apis[apis.env].exploreVideos;
            
            axios(endPoint, {
                method  :   "post",
                data  : { excludeVideoIDs : this.excludeVideoIDs }
            })
            .then((res) => {
                
                this.loadMoreVideosProgress = false;
                
                var videosData = res.data.result.data
                
                if(videosData.length === 0) {
                    this.$store.commit("User/showErrorAlert", "There are no more videos");
                } else {
                    
                    if(this.excludeVideoIDs.length === 0){
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Explore Video Displayed");
                    } else {
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Explore Video (Load more Video) button clicked");
                    }
                    
                    for (let i = 0; i < videosData.length; i++) {
                        const element = videosData[i];
                        this.exploreVideosArray.push(element)
                        this.excludeVideoIDs.push(element._id);
                    }

                    console.log("excludeVideoIDs 2 : ",this.excludeVideoIDs);


                }
            
            })
            .catch((err)    => { console.log("exploreVideos() Error : ", err)   });

        },
        
        pressEnter: function() {
            window.location.href = "/search/" + this.searchQuery;
            this.searchQuery = "";
        },
        
        redirectToSign() { window.location.href = "/signin" },
        
        postNotificationToken() {
            
            messaging.getToken().then((token) => {
                
                if (this.userData.token) {
                    
                    this.notificationprogress = false;
                    this.notificationPermission = false;
                    
                    let endPoint = apis[apis.env].saveNotificationToken;
                    
                    let data = {
                        token : token,
                        device: this.deviceName(),
                        browser: this.browserName()
                    }
                    
                    axios(endPoint, {
                        method          :   "post",
                        data,
                        withCredentials :   true,
                        headers         : { authorization : this.userData.token }
                    })
                    .then((res) => {})
                    .catch((err) => { console.log("Error in postNotificationToken ", err) });
                
                } else {
                    console.log("User signed out but has allowed push notification");
                }
            
            });
        
        },
        
        showInstallPwaBanner() {
            
            if (deferredPrompt === undefined) {
                
                this.alreadyinstalled = true;
                this.pwapopup = false;
                
            } else {
                
                deferredPrompt.prompt();
                
                deferredPrompt.userChoice.then((choiceResult) => { 
                    if (choiceResult.outcome === "accepted") { this.installPwaBanner = false }
                    deferredPrompt = null;
                });
            }
        },
        
        reqforpermission() {
            
            if (firebase.messaging.isSupported()) {
                
                messaging.requestPermission()
                .then(() => {
                    this.notificationprogress = true;
                    this.postNotificationToken();
                })
                .catch((err) => {
                    console.log("exploreVideos() Error : ", err);
                    this.permission = true;
                });
            
            } else {
                
                this.$store.commit( "User/showErrorAlert", "Push Notifications is not supported in Safari, We kindly recommend you to use chrome");
                this.permission = true;
            
            }
        },
        
        deviceName() {
            let ua = navigator.userAgent;
            
            if (    ua.match(/Android/i)    )   {   return "Android"    };
            if (    ua.match(/BlackBerry/i) )   {   return "BlackBerry" };
            if (    ua.match(/iPhone/i)     )   {   return "iPhone"     };
            if (    ua.match(/iPad/i)       )   {   return "iPad"       };
            if (    ua.match(/iPod/i)       )   {   return "iPod"       };
            if (    ua.match(/IEMobile/i)   )   {   return "IEMobile"   };
            if (    ua.match(/Mac/i)        )   {   return "Mac"        };
            if (    ua.match(/Windows/i)    )   {   return "Windows"    };
            
            return "device";
        },
        
        browserName() {
            
            let ua = navigator.userAgent;
            
            if  (   ua.match(/Firefox/i)                        )   {   return  "Firefox"   };
            if  (   ua.match(/Chrome/i) && ua.match(/Safari/i)  )   {   return  "Chrome"    };
            if  (   ua.match(/Safari/i)                         )   {   return  "Safari"    };
            
            return "Browser";
        },
        
        followPreskilet(uid) {
              console.log("my uid : ", uid);
                
                this.$store.commit("User/toggleProgressLoader", true);

                let data        = { userId: uid };
                let endPoint    = apis[apis.env].follow;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {

                    this.$store.commit("User/toggleProgressLoader", false);
                    this.$store.commit("User/showSuccessAlert", res.data.result);


                    this.suggestData.forEach(x => {
                        if(JSON.stringify(x._id) === JSON.stringify(uid)){
                            x.msg = "following";
                        }
                        
                    });

                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Follow Operation on Home from suggestions");                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

        },

        getSuggestions() {
            
            let endPoint = apis[apis.env].getSuggestions;
            
            axios(endPoint, {
                method          :   "get",
                withCredentials :   true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                
                if (res.data.result) {
                    
                    this.userId = res.data.result._id;
                    var userAlreadyFollowing = res.data.result.userFollowing;
                    var tempSuggestData = res.data.result.suggest;
                    
                    // To put all the data in one single array
                    let finalCycle = [];
                    for (let i = 0; i < tempSuggestData.length; i++) {
                    for (let j = 0; j < tempSuggestData[i].following.length; j++) {
                        // To not show the user the himself as a suggestions
                        if (tempSuggestData[i].following[j]._id != this.userId) {
                            finalCycle.push(tempSuggestData[i].following[j]);
                        }
                    }
                    }
                    
                    for (let jm = 0; jm < finalCycle.length; jm++) {
                        // console.log("docs ", jm, " ", finalCycle[jm])
                    }
                    
                    // Removing all the duplicates from the array
                    for (let i = 0; i < finalCycle.length; i++) {
                    for (let j = 0; j < finalCycle.length; j++) {
                        if (i != j) {
                        if (finalCycle[i]._id == finalCycle[j]._id) {
                            finalCycle.splice(j, 1);
                        }
                        }
                    }
                    }
                    
                    for (let jm = 0; jm < finalCycle.length; jm++) {
                        // console.log("docs ", jm, " ", finalCycle[jm])
                    }
                    
                    var uniqueChars = [];
                    
                    function doesFollow(myid) {
                    for (let i = 0; i < userAlreadyFollowing.length; i++) {
                        if (userAlreadyFollowing[i]._id == myid) {
                        return true;
                        }
                    }
                    return false;
                    }

                    var uniqueChars = [];
                    for (let j = 0; j < finalCycle.length; j++) {
                    if (!doesFollow(finalCycle[j]._id)) {
                        uniqueChars.push(finalCycle[j]);
                    }
                    }
                    this.suggestData = uniqueChars;
                    
                    var suggestDataWithMsg = uniqueChars.map((x)=>{
                        
                        return {
                        email : x.email,
                        followers : x.followers,
                        profile: x.profile,
                        _id : x._id,
                        msg: "follow"
                    }
                    });
                    
                               console.log("suggestions : ", suggestDataWithMsg);
                
                suggestDataWithMsg = suggestDataWithMsg.sort(() => Math.random() - 0.5)
                
                this.suggestData = suggestDataWithMsg;

                console.log("shuffled : ", this.suggestData);


                    if (this.suggestData.length == 1) {
                    this.isLimitedLength = false;
                    }


                }

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Suggestions Displayed on Home Page");
            
            })
            .catch((err) => { console.log("getSuggestions Error : ", err) });
        
        }
        
    }
};
</script>
