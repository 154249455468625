<template>
<div class="home">
    
    <v-container>
        
        <v-layout row wrap justify-space-around>

            <v-flex xs11 md11>

                <v-row justify="center" class="text-center pa-0 ma-0">
                    <v-card-title v-text="'Contact Us'"></v-card-title>
                </v-row>

                <v-card color="white" outlined>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-text>

                                <div class="ContactUs">

                                    <p>Email: contactus.preskilet@gmail.com</p>
                                    <p>
                                    Address: Bismilla building , Wakad gaothan , Behind
                                    Alamgir masjid, Pune-411057
                                    </p>

                                </div>

                            </v-card-text>

                        </div>

                    </div>

                </v-card>

                <br />
                <br />
                <br />

            </v-flex>
            
        </v-layout>
        
    </v-container>
    
</div>
</template>

<style scoped>

h2 {
  margin-bottom : 3%;
}

</style>

<script>

import { mapGetters } from "vuex";

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

export default {
    
    name        : "ContactUs",
    
    computed    : { ...mapGetters({ userData: "User/userData" }) },
    
    created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Contact Us Page");

        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");

    }

};

</script>
