<template>
<div>
    
    <v-container>
        
        <v-card class="mx-auto" max-width="574" flat>

            <v-card-text style="background: white;" class="text-center">

                <h3>
                    Welcome to Nestimatic, Please Sign-in With Google to use our services
                </h3>

                <br />

                <v-img
                    style="margin:auto;"
                    class="text-center"
                    width="300"
                    height="300"
                    :src="vectorImages.preskiletAnimatedIcon"
                ></v-img>

            </v-card-text>

            <v-card-text style="background: white;" class="text-center">

                <i>
                    By signing in you are agree to our
                    <router-link router to="/termsandconditions"
                    >terms & conditions</router-link
                    >
                    and
                    <router-link router to="/privacypolicy"> PrivacyPolicy</router-link>
                </i>

                <div id="firebaseui-auth-container"></div>

            </v-card-text>

        </v-card>

    </v-container>

</div>
</template>

<script>

import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;
import firebase from "firebase/app";
import "firebase/auth";
import * as firebaseui from "firebaseui";

import "firebase/firebase-analytics";
var firebaseAnlytics;


export default {
    
    name        : "Signin",
    
    computed    : { ...mapGetters({ userData: "User/userData" }) },

    created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Signin Page");

        this.$store.commit("User/toggleProgressLoader", false);
        let that = this;
        
        firebase.auth().onAuthStateChanged((user) => {
        
            if (user) {
                
                user
                .getIdToken(true)
                .then(function(userIdToken) {
                    
                    window.localStorage.email = user.email;
                    window.localStorage.token = userIdToken;

                    let endPoint = apis[apis.env].signInUser;
                    that.$store.commit("User/toggleProgressLoader", true);
                    
                    axios(endPoint, {
                        method          : "post",
                        withCredentials : true,
                        headers         : { Authorization   : userIdToken },
                    })
                    .then((res) => {
                      console.log("sigin res : ", res);
                      that.$store.commit("User/toggleProgressLoader", false);
                      
                      let account = res?.data?.result?.accountType;
                      let professionTypes = ["Student", "Teacher", "Candidate", "Recruiter"];
                      
                      if (professionTypes.includes(account)) {
                          window.localStorage.accountType = account;
                          that.$store.commit("User/setAccountType", account);
                          window.location.href = "/";
                      } else {
                          window.location.href = "/selectaccounttype";
                      }
                    
                    })
                    .catch((err) => {
                        
                        //need to be removed
                        // window.location.href = "/selectaccounttype";

                        that.$store.commit("User/toggleProgressLoader", false);
                        console.log("Error In sign In",err);
                        let errorMsg = utilities.errorHandler(err);
                        that.$store.commit("User/showErrorAlert", errorMsg);
                    
                    });

                })
                .catch(function(err) {
                    
                    that.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in sign In",err);
                    let errorMsg = utilities.errorHandler(err);
                    that.$store.commit("User/showErrorAlert", errorMsg);
                
                });

            }

        });

    },

    mounted() {
        
        var uiConfig = {
            
            signInSuccessUrl    : "/signin",
            signInOptions       : [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
        
        };

        var ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(firebase.auth());
        ui.start("#firebaseui-auth-container", uiConfig);
    
    },

    data() {
        
        return {
            vectorImages: utilities.vectorImages,
        };

    },

};

</script>
