<template>

<div class="home" v-if="!userData.signedIn">
    
    <v-container>
        
        <v-dialog v-model="dialog" persistent max-width="560">

            <v-card>

                <v-card-title class="headline">Copy link</v-card-title>

                <v-card-text
                    >Please upadate your browser so you can directly share your resume,
                    otherwise you can use the below link, click on copy button to copy
                    the link</v-card-text
                >

                <v-text-field
                    id="myinput"
                    v-model="shareableLink"
                    class="ma-3"
                ></v-text-field>

                <v-card-actions>
                    
                    <v-spacer></v-spacer>

                    <div class="tooltip">

                        <button>

                            <span class="tooltiptext" id="myTooltip"
                            >Copy to clipboard</span
                            >
                            <v-btn
                            color="green darken-1"
                            text
                            @click="myLink"
                            @mouseout="outFunc"
                            >Copy</v-btn>

                        </button>

                    </div>

                    <v-btn color="green darken-1" text @click="dialog = false"
                    >Close</v-btn>

                </v-card-actions>

            </v-card>

        </v-dialog>
        
        <v-row dense class="pa-0 ma-0">
            
            <v-col cols="12">

                <v-card flat>

                    <v-img
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.Welcome"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Nestimatic'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'Welcome to Nestimatic, before you start, please go through a brief introduction of sections in our website, you will find all of this options in top left menu, once you create account with us. it will help you to use our services easily.'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>

                            <v-img
                            style="border-radius: 10%;"
                            :src="vectorImages.Welcome"
                            ></v-img>

                        </v-avatar>

                    </div>

                </v-card>

                <br />

                <v-card flat>

                    <v-img
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.search"
                    ></v-img>
                    
                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title class="headline" v-text="'Search'"></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'Search your teachers by names with the help of nestimatic, to follow groups created by them & submit the assignments'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                            <v-img :src="vectorImages.search"></v-img>
                        </v-avatar>

                    </div>

                </v-card>

                <br />
                <v-card flat>

                    <v-img
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.profile"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Profile'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'Profile is one of the most important part of the Nestimatic Profile. Make sure you enter corrent information, because this information is used while grading your assignements & taking your attendance.'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                            <v-img :src="vectorImages.profile"></v-img>
                        </v-avatar>

                    </div>

                </v-card>

                <br />

                <v-card flat>

                    <v-img
                    style="border-radius: 100%;"
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.pret"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title class="headline" v-text="'Upload Assignments'"></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'You can upload your assignments from this section'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                            <v-img :src="vectorImages.pret"></v-img>
                        </v-avatar>

                    </div>

                </v-card>

                <br />

                
                <v-card flat>

                    <v-img
                    style="border-radius: 100%;"
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.group"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Groups'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'You can create groups (Batch-wise, Division-wise, Department-wise, College-wise) on preskilet to create tasks (assignments) for that group. Interested Users can follow those groups and you will get a group request. As soon as you accept the request, each group member will get notifications whenever you assign task (Assignment) for that group.'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>

                            <v-img
                            style="border-radius: 10%;"
                            :src="vectorImages.group"
                            ></v-img>

                        </v-avatar>

                    </div>

                </v-card>

                <br />

                <v-card flat>

                    <v-img
                    style="border-radius: 100%;"
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.task"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Tasks'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'You can create tasks on various topics so that your group members can upload assignments for respective task and then you can grade those assignments and generate a grade report in excel sheet.'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>

                            <v-img
                            style="border-radius: 10%;"
                            :src="vectorImages.task"
                            ></v-img>

                        </v-avatar>

                    </div>

                </v-card>

                <br />
                
                <v-card flat>

                    <v-img
                    style="border-radius: 100%;"
                    class="hidden-md-and-up white--text align-end"
                    :src="vectorImages.excel"
                    ></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Download Excel'"
                            ></v-card-title>

                            <v-card-subtitle
                            v-text="
                                'You can download excel sheet for a particular group containing grades of every task assignments (and also attendance of students) along with details of group members such as Registration number, Roll number, Batch name, etc in just a single click!'
                            "
                            ></v-card-subtitle>

                        </div>

                        <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>

                            <v-img
                            style="border-radius: 10%;"
                            :src="vectorImages.excel"
                            ></v-img>

                        </v-avatar>

                    </div>

                </v-card>

                <br />
                
            </v-col>

        </v-row>

    </v-container>

</div>
</template>

<style scoped>

#create .v-speed-dial {
  position  : absolute;
}

#create .v-btn--floating {
  position  : relative;
}
.tooltip {
  position  : relative;
  display   : inline-block;
}

.tooltip .tooltiptext {
  visibility        : hidden;
  width             : 140px;
  background-color  : #555;
  color             : #fff;
  text-align        : center;
  border-radius     : 6px;
  padding           : 5px;
  position          : absolute;
  z-index           : 1;
  bottom            : 150%;
  left              : 50%;
  margin-left       : -75px;
  opacity           : 0;
  transition        : opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content       : "";
  position      : absolute;
  top           : 100%;
  left          : 50%;
  margin-left   : -5px;
  border-width  : 5px;
  border-style  : solid;
  border-color  : #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility    : visible;
  opacity       : 1;
}

</style>

<script>

import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;


export default {
    
    name        : "LandUpInfo",
    
    data() {

        return {

            vectorImages    : utilities.vectorImages,
            recommendations : [],
            shareableLink   : "https://preskilet.com/",
            dialog          : false

        };

    },

    computed    : { ...mapGetters({ userData    : "User/userData" }) },
    
    created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("LandUpInfo Component");

        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
    
    },

    mounted() {

        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
        this.$store.commit("User/toggleProgressLoader", false);
        this.shareableLink = this.shareableLink + this.userData.email;
    
    },

    methods: {
        
        myLink() {
            
            var tooltip         = document.getElementById("myTooltip");
            tooltip.innerHTML   = "Link Copied !";
            var copiedText      = document.getElementById("myinput");

            copiedText.select();
            copiedText.setSelectionRange(0, 99999);
            document.execCommand("copy");
        
        },

        outFunc() {

            var tooltip         = document.getElementById("myTooltip");
            tooltip.innerHTML   = "Copy to clipboard";
        
        },
        
        share() {

            if (navigator.share) {
                
                navigator
                .share({
                    title: "preskilet",
                    text: "Check out my live Video Profile on Preskilet",
                    url: this.shareableLink,
                })
                .then(() => console.log("Successful share is done"))
                .catch((err) => {
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });

            } else {

                console.log("outdated browser , sharing cant be done directly, so share link using model");
                this.dialog = true;
            
            }
    
        }
    
    },

};

</script>
